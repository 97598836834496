import React from "react";
import "./server-error.css";
const ServerError = function ({ children }) {
  return (
    <div className="error-container">
      <div className="error-text">{children}</div>
    </div>
  );
};

export default ServerError;
