export const SERVER_UNAVAILABLE_MESSAGE = "The server is currently unavailable. Please try again.";

export const SERVER_ERRORS = [
  {
    errorCode: "VR_WAPI_InvalidAccessKey",
    description: "Access Key is Invalid",
    sectionKey: "",
    errorMessage: SERVER_UNAVAILABLE_MESSAGE,
  },
  {
    errorCode: "VR_WAPI_InvalidAction",
    description: "Action not found.",
    sectionKey: "",
    errorMessage: SERVER_UNAVAILABLE_MESSAGE,
  },
  {
    errorCode: "VR_WAPI_InvalidAPIbatch",
    description: "Batch value is Invalid",
    sectionKey: "",
    errorMessage: SERVER_UNAVAILABLE_MESSAGE,
  },
  {
    errorCode: "VR_WAPI_MissingAccessKey",
    description:
      "Please provide the access key in [sysparm_AuthKey] parameter in the link to proceed",
    sectionKey: "",
    errorMessage: SERVER_UNAVAILABLE_MESSAGE,
  },
  {
    errorCode: "VR_WAPI_MissingAPIaction",
    description:
      "Please provide the GET action in [sysparm_action] parameter in the link to proceed.",
    sectionKey: "",
    errorMessage: SERVER_UNAVAILABLE_MESSAGE,
  },
  {
    errorCode: "VR_WAPI_MissingCounty",
    description: "Please provide the county in [sysparm_County] parameter in the link to proceed.",
    sectionKey: "",
    errorMessage: SERVER_UNAVAILABLE_MESSAGE,
  },
  {
    errorCode: "VR_WAPI_MissingLanguage",
    description:
      "Please provide the Language code in [sysparm_Language] parameter in the link to proceed",
    sectionKey: "",
    errorMessage: SERVER_UNAVAILABLE_MESSAGE,
  },
  {
    errorCode: "VR_WAPI_InvalidOVRCounty",
    description: "Your county of residence is required.",
    sectionKey: "address",
    errorMessage: "Please fix your county of residence",
  },
  {
    errorCode: "VR_WAPI_InvalidOVRDL",
    description: "please provide valid DL or pick continuesubmit checkbox",
    sectionKey: "penndot",
    errorMessage: "The PennDOT number was invalid. Please correct and try again",
  },
  {
    errorCode: "VR_WAPI_InvalidOVRDLformat",
    description: "Please enter a valid 8 digit PA driver's license or PennDOT ID card number",
    sectionKey: "penndot",
    errorMessage: "The PennDOT number was invalid. Please correct and try again",
  },
  {
    errorCode: "VR_WAPI_InvalidOVRDOB",
    description: "Please input a valid birth date",
    sectionKey: "dob",
    errorMessage: "Please enter a valide birth date",
  },
  {
    errorCode: "VR_WAPI_InvalidOVRemail",
    description: "The format of the email address is incorrect. Please correct and try again.",
    sectionKey: "email",
    errorMessage: "The format of the email address is incorrect. Please correct and try again.",
  },
  {
    errorCode: "VR_WAPI_InvalidOVRmailingzipcode",
    description: "The zip code must be 5 digits or 9 digits (zip code + 4)",
    sectionKey: "address",
    errorMessage: "The zip code was invalid.  Please correct and try again.",
  },
  {
    errorCode: "VR_WAPI_InvalidOVRphone",
    description: "The phone number provided is not valid. Please enter a valid phone number.",
    sectionKey: "",
    errorMessage: "The phone number provided is not valid.  Please correct and try again.",
  },
  {
    errorCode: "VR_WAPI_InvalidOVRPreviousCounty",
    description: "Previous County of Registration is required for an Address Change application",
    sectionKey: "previousAddress",
    errorMessage: "Previous County of Registration is required for an Address Change application",
  },
  {
    errorCode: "VR_WAPI_InvalidOVRPreviouszipcode",
    description: "Please enter a valid 5 digit zip code",
    sectionKey: "previousAddress",
    errorMessage: "Previous address zip code was invalid.  Please correct and try again.",
  },
  {
    errorCode: "VR_WAPI_InvalidOVRSSNformat",
    description: "Please enter the LAST FOUR digits of your Social Security numbe",
    sectionKey: "ssn",
    errorMessage: "Please enter the LAST FOUR digits of your Social Security numbe",
  },
  {
    errorCode: "VR_WAPI_InvalidOVRzipcode",
    description: "Please enter a valid 5 digit zip code.",
    sectionKey: "address",
    errorMessage: "The zip code was invalid.  Please correct and try again.",
  },
  {
    errorCode: "VR_WAPI_invalidpreviousregyea",
    description: "Please input valid year",
    sectionKey: "previousRegistrationYear",
    errorMessage: "Please input valid year",
  },
  {
    errorCode: "VR_WAPI_InvalidReason",
    description:
      "Please select any one reason - New Application or Update Application , both are not allowed",
    sectionKey: "",
    errorMessage: "",
  },
  {
    errorCode: "VR_WAPI_InvalidOVRAssistedpersonphone",
    description: "The phone number provided is not valid. Please enter a valid phone numbe",
    sectionKey: "helper",
    errorMessage: "The phone number provided for the helper is not valid.",
  },
  {
    errorCode: "VR_WAPI_InvalidOVRsecondemail",
    description: "The format of the email address is incorrect. Please correct and try again.",
    sectionKey: "",
    errorMessage: "",
  },
  {
    errorCode: "VR_WAPI_InvalidOVRPoliticalParty",
    description: "Please select a political party",
    sectionKey: "politicalParty",
    errorMessage: "Please select a political party",
  },
  {
    errorCode: "VR_WAPI_InvalidOVRmailinzipcode",
    description: "Missing MailIn Zipcode",
    sectionKey: "mailInBallot",
    errorMessage: "The mailin address is missing a zip code. Please correct and try again.",
  },
  {
    errorCode: "VR_WAPI_MissingOVRassistancedeclaration",
    description: "Please indicate assistance was provided with the completion of this form",
    sectionKey: "helper",
    errorMessage: "Please indicate that you accept the assitance decleration",
  },
  {
    errorCode: "VR_WAPI_MissingOVRcity",
    description: "Your city is required",
    sectionKey: "address",
    errorMessage: "A city is required. Please correct and try again.",
  },
  {
    errorCode: "VR_WAPI_MissingOVRcounty",
    description: "Your county of residence is required",
    sectionKey: "address",
    errorMessage: "A county is required. Please correct and try again.",
  },
  {
    errorCode: "VR_WAPI_MissingOVRdeclaration1",
    description: "Please confirm you have read and agree to the terms",
    sectionKey: "",
    errorMessage: "",
  },
  {
    errorCode: "VR_WAPI_MissingOVRDL",
    description:
      "Please supply either a PA driver's license or PennDOT ID card number, the last four digits of your SSN, or click the check box",
    sectionKey: "penndot",
    errorMessage:
      "Please supply either a PA driver's license, PennDOT ID card number or the last four digits of your SSN",
  },
  {
    errorCode: "VR_WAPI_MissingOVRfirstname",
    description: "Your first name is required.",
    sectionKey: "name",
    errorMessage: "Your first name is required.",
  },
  {
    errorCode: "VR_WAPI_MissingOVRinterpreterlang",
    description: "Required if interpreter is checked",
    sectionKey: "",
    errorMessage: "",
  },
  {
    errorCode: "VR_WAPI_MissingOVRisageover1",
    description:
      "Will you be 18 years or older on or before election day? You must provide a response before continuing",
    sectionKey: "",
    errorMessage: "",
  },
  {
    errorCode: "VR_WAPI_MissingOVRisuscitizen",
    description: "Are you a citizen of the U.S.? You must provide a response before continuing",
    sectionKey: "",
    errorMessage: "",
  },
  {
    errorCode: "VR_WAPI_MissingOVRlastname",
    description: "Your last name is required",
    sectionKey: "name",
    errorMessage: "Your last name is required",
  },
  {
    errorCode: "VR_WAPI_MissingOVROtherParty",
    description:
      "Warning - Party is not selected. If Other is selected, the Other party text box should be completed",
    sectionKey: "politicalParty",
    errorMessage: 'You selected "Other" for your political party. A party name is required.',
  },
  {
    errorCode: "VR_WAPI_MissingOVRPoliticalParty",
    description:
      "Warning - Party is not selected. If Other is selected, the Other party text box should be completed",
    sectionKey: "politicalParty",
    errorMessage: "You must select a political party",
  },
  {
    errorCode: "VR_WAPI_MissingOVRPreviousAddress",
    description: "Address of Previous Registration is required for an Address Change application",
    sectionKey: "previousAddress",
    errorMessage: "Address of Previous Registration is required for an Address Change application",
  },
  {
    errorCode: "VR_WAPI_MissingOVRPreviousCity",
    description: "City of Previous Registration is required for an Address Change application",
    sectionKey: "previousAddress",
    errorMessage: "City of Previous Registration is required for an Address Change application",
  },
  {
    errorCode: "VR_WAPI_MissingOVRPreviousFirstName",
    description: "Previous First Name is required for a Name Change application",
    sectionKey: "previousName",
    errorMessage: "Previous First Name is required for a Name Change application",
  },
  {
    errorCode: "VR_WAPI_MissingOVRPreviousLastName",
    description: "Previous Last Name is required for a Name Change application",
    sectionKey: "previousName",
    errorMessage: "Previous Last Name is required for a Name Change application",
  },
  {
    errorCode: "VR_WAPI_MissingOVRPreviousZipCode",
    description: "Zip of Previous Registration is required for an Address Change application",
    sectionKey: "previousAddress",
    errorMessage: "Zip of Previous Registration is required for an Address Change application",
  },
  {
    errorCode: "VR_WAPI_MissingOVRSSNDL",
    description:
      "Please supply either a PA driver's license or PennDOT ID card number, the last four digits of your SSN, or click the check box",
    sectionKey: "penndot",
    errorMessage:
      "Please supply either a PA driver's license, PennDOT ID card number or the last four digits of your SSN",
  },
  {
    errorCode: "VR_WAPI_MissingOVRstreetaddress",
    description: "Your street address is required.",
    sectionKey: "address",
    errorMessage: "Your street address is required.",
  },
  {
    errorCode: "VR_WAPI_MissingOVRtypeofassistance",
    description: "Please select the type of assistance required",
    sectionKey: "",
    errorMessage: "",
  },
  {
    errorCode: "VR_WAPI_MissingOVRzipcode",
    description: "Your zip code is required",
    sectionKey: "address",
    errorMessage: "Your zip code is required",
  },
  {
    errorCode: "VR_WAPI_MissingReason",
    description: "Please check at least one box",
    sectionKey: "",
    errorMessage: "",
  },
  {
    errorCode: "VR_WAPI_MissingOVRParty",
    description: "Please select a political party",
    sectionKey: "politicalParty",
    errorMessage: "Please select a political party",
  },
  {
    errorCode: "VR_WAPI_MissingOVRmailinballotaddr",
    description: "Missing MailIn Ballot Address",
    sectionKey: "mailInBallot",
    errorMessage: "An address is required for your mailin ballot",
  },
  {
    errorCode: "VR_WAPI_MissingOVRmailincity",
    description: "Missing MailIn City",
    sectionKey: "mailInBallot",
    errorMessage: "A city is required for your mailin ballot",
  },
  {
    errorCode: "VR_WAPI_MissingOVRmailinstate",
    description: "Missing MailIn State",
    sectionKey: "mailInBallot",
    errorMessage: "A state is required for your mailin ballot",
  },
  {
    errorCode: "VR_WAPI_MissingOVRmailinlivedsince",
    description: "Missing MailIn lived since",
    sectionKey: "mailInBallot",
    errorMessage: "A lived in since year is required for your mailin ballot",
  },
  {
    errorCode: "VR_WAPI_MissingOVRmailindeclaration",
    description: "Missing MailIn Declaration",
    sectionKey: "mailInBallot",
    errorMessage: "You must agree to the delaration for your mailin ballot",
  },
  {
    errorCode: "VR_WAPI_MissingAddress",
    description:
      "A complete mailing or residential address is required for your application to be submitted online. Please use the link at the top of the page to print a blank voter registration application. Please complete, sign and date it then mail it to your county voter registration office. Do not FAX your application form.",
    sectionKey: "address",
    errorMessage: "A complete mailing or residential address is required for your application.",
  },
  {
    errorCode: "VR_WAPI_PennDOTServiceDown",
    description: "PennDOT server is down.",
    sectionKey: "",
    errorMessage: SERVER_UNAVAILABLE_MESSAGE,
  },
  {
    errorCode: "VR_WAPI_RequestError",
    description: "WebAPi request is Invalid",
    sectionKey: "",
    errorMessage: SERVER_UNAVAILABLE_MESSAGE,
  },
  {
    errorCode: "VR_WAPI_ServiceError",
    description: "Signature service is down",
    sectionKey: "",
    errorMessage: SERVER_UNAVAILABLE_MESSAGE,
  },
  {
    errorCode: "VR_WAPI_SystemError",
    description:
      "We're sorry, but the system cannot verify your information and complete your application right now. Try again",
    sectionKey: "",
    errorMessage: SERVER_UNAVAILABLE_MESSAGE,
  },
  {
    errorCode: "VR_WAPI_Invalidsignaturestring",
    description: "Your upload was not successful. Please try again",
    sectionKey: "ssn",
    errorMessage: "Your signature image upload was not successful, please try again.",
  },
  {
    errorCode: "VR_WAPI_Invalidsignaturetype",
    description: "Please choose one of the following file types: .TIFF, .JPG, .BMP and .PNG",
    sectionKey: "ssn",
    errorMessage: "Your signature image file type was invalid (.TIFF, .JPG, .BMP and .PNG only)",
  },
  {
    errorCode: "VR_WAPI_Invalidsignaturesize",
    description: "Please upload an image file size less than 5MB",
    sectionKey: "ssn",
    errorMessage:
      "Your signature image file was too large. Please upload an image file smaller than 5MB",
  },
  {
    errorCode: "VR_WAPI_Invalidsignaturedimension",
    description: "The image size should be equal to 180 x 60 pixels",
    sectionKey: "ssn",
    errorMessage: "Your signature image size was invalid. It should be equal to 180 x 60 pixels",
  },
  {
    errorCode: "VR_WAPI_Invalidsignaturecontrast",
    description: "Your upload was not successful. Please try again",
    sectionKey: "ssn",
    errorMessage:
      "Your signature image was too difficult to read. Please try to take the image again.",
  },
  {
    errorCode: "VR_WAPI_Invalidsignatureresolution",
    description:
      "Your uploaded signature does not meet the 96.00 dpi requirements. Please upload an image file meeting or exceeding this requirement",
    sectionKey: "ssn",
    errorMessage:
      "Your uploaded signature does not meet the 96.00 dpi requirements. Please upload an image file meeting or exceeding this requirement",
  },
  {
    errorCode: "VR_WAPI_MailinNotEligible",
    description: "MailIn Not Eligible",
    sectionKey: "",
    errorMessage: "",
  },
];
