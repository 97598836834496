import React from "react";
import styled from "styled-components";

const Button = styled.button`
  margin: 20px 0;
  padding: 12px 24px;
  width: 100%;
  border-radius: 4px;
`;

const OVROrVBM = ({ updateValue }) => {
  const linkClickHandler = (e, val) => {
    e.preventDefault();
    updateValue(val);
  };

  return (
    <div>
      <Button onClick={(e) => linkClickHandler(e, "No")}>I want to register to vote.</Button>
      <Button
        onClick={(e) =>
          linkClickHandler(e, "Yes")
        }>{`I'm already registered and I want to request a mail-in ballot.`}</Button>
    </div>
  );
};

export default OVROrVBM;
