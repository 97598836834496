export default [
  "emailupdates",
  "email",
  "name-first",
  "name-middle",
  "name-last",
  "name-suffix",
  "phone-number",
  "address-zip",
  "address-state",
  "address-street",
  "address-city",
  "address-county",
  "address-unit-number",
  "address-municipality",
  "mailing-address-street",
  "mailing-address-city",
  "mailing-address-state",
  "mailing-address-zip",
  "political-party",
  "gender",
  "race",
  "date-of-birth",
  "previous-first-name",
  "previous-last-name",
  "previous-address",
  "previous-city",
  "previous-state",
  "previous-zip",
  "previous-county",
  "new-registration",
  "change-name",
  "change-address",
  "change-party",
  "fed-state-employee",
  "voter-registration-number",
  "annual-ballot-request",
  "source",
];
