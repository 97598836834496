import React, { useEffect, useRef, useState } from "react";
import ReactLoading from "react-loading";
import { createBrowserHistory } from "history";
import moment from "moment";
import setupApplicationData from "../setupApplication.json";
import handlers from "./handlers";
import ServerError from "./ServerError";
import { SERVER_ERRORS, SERVER_UNAVAILABLE_MESSAGE } from "./errors";
import l10n from "../../services/localization";

import getFormFields, { findFieldInPage } from "../../form-fields/form-fields-pennsylvania";
import baseConfig from "../../config/config-base";
import customConfig from "../../config";
import { ballotRequestDeclined, ballotRequestSuccess } from "./ballot-request";
import "../../styles/main.css";
import ex from "../../ex.svg";
import paLogo from "../../tfc_logo.png";
import * as Sentry from "@sentry/react";
import logFields from "./log-fields";

const config = { ...customConfig, ...baseConfig };

const defaultValidator = (value) => !value && "This field is required";

const isElementActive = ({ dependency, dependencyNot, hidefn }, formData) => {
  let dependencyMet = true;
  if (dependency && dependency.length > 0) {
    dependencyMet = dependency.some((d) => {
      return !!formData[d];
    });
  }
  return (
    dependencyMet && (!dependencyNot || !formData[dependencyNot]) && (!hidefn || !hidefn(formData))
  );
};

const getPageStatus = ({ elements }, formData) => {
  const activeElements = elements.filter((element) => isElementActive(element, formData));

  if (activeElements[0].page === "reason") {
    let checkboxSelected = false;

    for (const element of activeElements) {
      // checkbox is selected
      if (element.handler === "checkbox" && formData[element.key]) {
        if (element.key === "change-name") {
          // if element is change-name, confirm user has input previous first and last name
          if (formData["previous-first-name"] && formData["previous-last-name"]) {
            checkboxSelected = true;
            break;
          }
        } else if (element.key === "change-address") {
          // if element is change-address, confirm user has input previous address
          if (
            formData["previous-address"] &&
            formData["previous-city"] &&
            formData["previous-zip"] &&
            formData["previous-county"]
          ) {
            checkboxSelected = true;
            break;
          }
        } else if (element.key === "change-party") {
          checkboxSelected = true;
          break;
        } else {
          // if element is neither change-name or change-address, we're happy with just checking the box
          checkboxSelected = true;
          break;
        }
        break;
      }
    }

    if (!checkboxSelected) {
      return "error";
    } else {
      return "complete";
    }
  }

  for (const element of elements) {
    /**
     * This is written to handle page status on page 4 (Reason). We want checkboxes, not radio buttons,
     * but this function makes it so "complete" means all checkboxes have been checked.
     * Instead of reworking the function, I did this shortcut because we are on a deadline.
     */
    if (element.page === "reason" && element.handler === "checkbox" && formData[element.key]) {
      return "complete";
    }

    /**
     * Page 6 exception, throw error if no address checkbox checked
     */
    if (element.key === "no-address") {
      for (const activeEl of activeElements) {
        if (activeEl.key === "no-address-selected") {
          return "error";
        }
      }
    }
    if (element.key === "no-address" && element.handler === "checkbox" && element.value === true) {
      return "error";
    }

    if (element.handler === "review") {
      return "complete";
    }

    if (
      element.key === "signatureimage" &&
      formData[element.key] &&
      ((formData["penndot-number"] && formData["penndot-number"].match(/^[0-9]{8}$/g)) ||
        (formData["no-penndot-number"] &&
          formData["social-4-digits"] &&
          formData["social-4-digits"].match(/^[0-9]{4}$/g)) ||
        formData["no-dl-or-penndot"])
    ) {
      return "complete";
    }
  }
  const complete = activeElements
    .filter(
      ({ handler, optional, readOnly }) =>
        handler !== "text" && handler !== "LinkToPage" && !optional && !readOnly,
    )
    .every(({ key }) => formData[key]);
  const errors = countErrors({ elements }, formData);
  return errors ? "error" : complete ? "complete" : "default";
};

const countErrors = ({ elements }, formData) => {
  const activeElements = elements.filter((element) => isElementActive(element, formData));
  return activeElements.filter(({ key, validator, optional }) => {
    if (!optional) {
      validator = validator || defaultValidator;
    }
    return key && validator && !!validator(formData[key] || "");
  }).length;
};

const parseQueryParams = () => {
  const sessionFormDataJson = window.sessionStorage.getItem("formData");
  let sessionFormData = {};
  if (sessionFormDataJson) sessionFormData = JSON.parse(sessionFormDataJson);

  const extraQueryParamsJson = window.sessionStorage.getItem("extraQueryParams");
  let extraQueryParams = {};
  if (extraQueryParamsJson) extraQueryParams = JSON.parse(extraQueryParamsJson);

  const params = new URLSearchParams(window.location.search);
  params.forEach((v, k) => {
    if (logFields.includes(k) && v > "") {
      if (k === "phone-number") {
        const cleaned = ("" + v).replace(/\D/g, "");
        let match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/);
        if (match) {
          v = `${match[1]}-${match[2]}-${match[3]}`;
        } else {
          v = "";
        }
      }
      sessionFormData[k] = v;
    } else {
      extraQueryParams[k] = v;
    }
  });
  return { sessionFormData, extraQueryParams };
};

const getStartingPage = () => {
  const sessionValue = window.sessionStorage.getItem("currentPageIndex");
  const params = new URLSearchParams(window.location.search);
  const paramPage = params.get("page");
  let startingPage = config.startPage;
  if (sessionValue) {
    const parsedValue = parseInt(sessionValue);
    startingPage = isNaN(parsedValue) ? startingPage : parsedValue;
  }
  if (paramPage) {
    const parsedParamValue = parseInt(paramPage);
    startingPage = isNaN(parsedParamValue) ? startingPage : parsedParamValue;
  }
  return startingPage;
};

export default function RegistrationForm() {
  const startingPage = getStartingPage();
  const [currentPageIndex, setCurrentPageIndexRaw] = useState(startingPage);
  const [currentPageSlug, setCurrentPageSlug] = useState();
  const [ready, setReady] = useState(false);
  const { sessionFormData, extraQueryParams } = parseQueryParams();
  const [formData, setFormData] = useState(sessionFormData);
  const [percentage, setPercentage] = useState(0);
  const [_showMenu, setShowMenu] = useState(false);
  const [forceValidation, setForceValidation] = useState(false);

  const [appJson, setAppJson] = useState();
  const [mailInBallotError, setMailInBallotError] = useState(false);
  const [submittedWithSigErrors, setSubmittedWithSigErrors] = useState(false);

  const [formFields, setFormFields] = useState();
  const [page, setPage] = useState();

  const [submitLoading, setSubmitLoading] = useState(false);
  const [VRApplicationID, setVRApplicationID] = useState(
    window.sessionStorage.getItem("VRApplicationID") ?? "",
  );
  const [VRApplicationDate, setVRApplicationDate] = useState(
    window.sessionStorage.getItem("VRApplicationDate") ?? "",
  );

  const [serverErrorMessage, setServerErrorMessage] = useState("");

  const [MailInApplicationID, setMailInApplicationID] = useState(
    window.sessionStorage.getItem("MailInApplicationID") ?? "",
  );
  const [MailInApplicationDate, setMailInApplicationDate] = useState(
    window.sessionStorage.getItem("MailInApplicationDate") ?? "",
  );
  const pageContainer = useRef();
  const history = createBrowserHistory();

  useEffect(() => {
    (async () => {
      let data = setupApplicationData;
      try {
        const response = await fetch("/setup");
        const newData = await response.json();
        delete newData["Text_OVRMailInApplnDeclaration"];
        data = { ...data, ...newData };
      } finally {
        setAppJson(data);
        const formFields = getFormFields(data, formData, []);
        setFormFields(formFields);
        if (formFields.length > currentPageIndex) {
          setCurrentPageSlug(formFields[currentPageIndex].slug);
        }
        setPage(formFields[currentPageIndex]);
        setReady(true);
      }
    })();
  }, []);

  useEffect(() => {
    window.sessionStorage.setItem("currentPageIndex", currentPageIndex);
    window.sessionStorage.setItem("currentPageSlug", currentPageSlug);
    history.push("/", { page: currentPageIndex });
  }, [currentPageIndex]);

  useEffect(() => {
    const unlisten = history.listen(() => {
      if (history.location.state["page"] !== currentPageIndex)
        setCurrentPageIndex(history.location.state.page);
    });

    return unlisten;
  }, [history]);

  useEffect(() => {
    window.sessionStorage.setItem("formData", JSON.stringify(formData));
  }, [formData]);

  useEffect(() => {
    window.sessionStorage.setItem("extraQueryParams", JSON.stringify(extraQueryParams));
  }, [extraQueryParams]);

  useEffect(() => {
    window.sessionStorage.setItem("VRApplicationID", VRApplicationID);
  }, [VRApplicationID]);

  useEffect(() => {
    window.sessionStorage.setItem("VRApplicationDate", VRApplicationDate);
  }, [VRApplicationDate]);

  useEffect(() => {
    window.sessionStorage.setItem("MailInApplicationID", MailInApplicationID);
  }, [MailInApplicationID]);

  useEffect(() => {
    window.sessionStorage.setItem("MailInApplicationDate", MailInApplicationDate);
  }, [MailInApplicationDate]);

  const onSubmitPage = () => {
    return currentPageSlug === "review";
  };

  const onConfirmationPage = () => {
    return currentPageSlug === "confirmation";
  };

  const onErrorPage = () => {
    return currentPageSlug === "submit-error";
  };

  const alreadySubmitted = () => {
    return !!VRApplicationDate;
  };

  const clearHistory = () => {
    history.replace("/");
  };

  function errorMessages(formFields, formData) {
    if (
      currentPageSlug === "eligibility" &&
      (formData["us-citizen"] === "No" || formData["18-or-older"] === "No")
    ) {
      return "You are not eligible to register at this time. You may not proceed";
    } else if (currentPageSlug === "eligibility") {
      return "You must provide a response before continuing.";
    } else if (currentPageSlug === "reason") {
      return "Please check at least one box";
    } else if (currentPageSlug === "about" && !("date-of-birth" in formData)) {
      return "Your birth date is required.";
    } else if (currentPageSlug === "identification") {
      let errArray = [];
      if (!formData["no-penndot-number"] && !formData["penndot-number"].match(/^[0-9]{8}$/g)) {
        errArray.push("Please supply a valid PennDOT number.");
      }
      if (formData["no-penndot-number"] && !formData["social-4-digits"].match(/^[0-9]{4}$/g)) {
        errArray.push("Please provide the last 4 digits of your social security number.");
      }
      if (!formData["signatureimage"]) {
        errArray.push("Please provide a signature.");
      }
      return errArray.join("\n");
    } else if (currentPageSlug === "party" && Object.keys(formData).length === 0) {
      return "Please select a political party.";
    } else if (
      currentPageSlug === "party" &&
      formData["political-party"] === "OTH" &&
      (!("other-political-party" in formData) || formData["other-political-party"] === "")
    ) {
      return "Warning - Party is not selected. If Other is selected, the Other party text box should be completed.";
    } else if (currentPageSlug === "voting-assistance" && !("require-voting-options" in formData)) {
      return "Please select the type of assistance required.";
    } else if (currentPageSlug === "declaration") {
      return "Please confirm you have read and agree to the terms";
    } else if (
      currentPageSlug === "mailin" &&
      formData["request-mail-ballot"] === "Yes" &&
      !("mail-in-ballot-options" in formData)
    ) {
      return "You must select an address where your ballot is to be mailed to.";
    } else {
      const numErrors = countErrors(formFields[currentPageIndex], formData);
      const errors = numErrors === 1 ? "error" : "errors";
      const pleaseFix = l10n.getString("actions.fix");
      return `${numErrors} ${errors} found \u2014 ${pleaseFix}`;
    }
  }

  const setCurrentPageIndex = (pageNum) => {
    setForceValidation(false);
    setCurrentPageIndexRaw(pageNum);
    setCurrentPageSlug(formFields[currentPageIndex].slug);
  };

  // When calculating percent done, there are 4 pages that we don't want to include towards our count
  // ex/ First page, review page, submit page
  const numExtraPages = 3;

  useEffect(() => {
    if (!formFields) {
      return;
    }
    if (currentPageSlug != "confirmation") {
      setServerErrorMessage("");
    }
    setShowMenu(false);
    const p = formFields[currentPageIndex] || formFields.slice(-1)[0];
    setPage(p);
    setCurrentPageSlug(p.slug);
    setPercentage(
      Math.floor(
        ((currentPageIndex - config.startPage) /
          (formFields.length - numExtraPages - config.startPage)) *
          100,
      ) + "%",
    );
    if (pageContainer.current) {
      pageContainer.current.focus();
    }
  }, [formFields, currentPageIndex]);

  const NextButton = ({ pageStatus, id, ...props }) => {
    switch (pageStatus) {
      case "complete":
        return (
          <button id={id} className="next-complete" {...props} disabled={submitLoading}>
            {l10n.getString(onSubmitPage() ? "actions.submit" : "actions.next")}
            <i className="fa fa-arrow-right" aria-hidden="true" />
          </button>
        );
      case "error":
        if (forceValidation) {
          const errorMsg = errorMessages(formFields, formData);
          return (
            <button id={id} className="next-error" {...props}>
              <img src={ex} alt="X" />
              <span className="longText">{errorMsg}</span>
            </button>
          );
        }
      // eslint-disable-next-line no-fallthrough
      default:
        return (
          <button id={id} className="next-default" {...props}>
            {l10n.getString(currentPageSlug === "confirmation" ? "actions.submit" : "actions.next")}
            <i className="fa fa-arrow-right" aria-hidden="true" />
          </button>
        );
    }
  };

  const BackButton = ({ ...props }) => {
    return (
      <button className="back" {...props}>
        <i className="fa fa-arrow-left" aria-hidden="true" />
        {l10n.getString("actions.back")}
      </button>
    );
  };

  const ResetButton = ({ ...props }) => {
    return (
      <button className="reset" {...props}>
        {l10n.getString("actions.reset")}
      </button>
    );
  };

  const maybeLogData = () => {
    if ([0, 2, 4, 5, 6, 8].includes(currentPageIndex)) {
      const dataToLog = {};
      logFields.forEach((key) => {
        if (formData[key] || formData[key] === false) {
          dataToLog[key] = formData[key];
        }
      });

      fetch("/log", {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(dataToLog),
      }).catch((err) => console.error(err));
    }
  };

  const onNextSubmit = async () => {
    setServerErrorMessage("");
    page.elements.forEach((element) => {
      element.error = false;
    });

    maybeLogData();

    if (getPageStatus(formFields[currentPageIndex], formData) === "complete") {
      if (onSubmitPage()) {
        setSubmitLoading(true);
        fetch("/submit", {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ ...formData, extras: extraQueryParams }),
        })
          .then((response) => {
            if ([400, 200].includes(response.status)) {
              return response
                .json()
                .then((result) => handleServerResponse(result))
                .catch((e) => {
                  console.error(e);
                });
            }
            Sentry.captureException(response.statusText);
            setSubmitLoading(false);
            handleServerError();
          })
          .catch((error) => {
            Sentry.captureException(error);
            setSubmitLoading(false);
            handleServerError();
            console.error(error.message);
          });
      } else {
        let incr = 1;
        if (
          currentPageSlug === "declaration" &&
          (formData["no-penndot-number"] || !config.pages.vbm)
        )
          incr = 2;
        setCurrentPageIndex((prev) => Math.min(prev + incr, formFields.length - 1));
      }
    } else {
      setForceValidation(true);
    }
  };

  const handleServerError = (errorMessage = SERVER_UNAVAILABLE_MESSAGE) => {
    setServerErrorMessage(errorMessage);
  };

  const handleServerResponse = async (result) => {
    if (result["ERROR"] && typeof result["ERROR"] === "string") {
      const errors = result["ERROR"].split(",");
      const onlyOne = errors.length === 1;
      if (errors.length) {
        setSubmitLoading(false);
      }
      errors.forEach((error) => {
        let field, errorDetails;
        switch (error) {
          case "VR_WAPI_PennDOTServiceDown":
          case "VR_WAPI_RequestError":
          case "VR_WAPI_ServiceError":
          case "VR_WAPI_SystemError":
            return handleServerError();
          case "VR_WAPI_MailinNotEligible":
            setMailInBallotError("VR_WAPI_MailinNotEligible");
            setCurrentPageIndex((prev) => Math.min(prev + 1, formFields.length - 1));
            break;
          default:
            if (formData["_vbm_flow"]) {
              setSubmitLoading(false);
              setCurrentPageIndex((prev) => Math.min(prev + 2, formFields.length - 1));
              clearHistory();
              return;
            }
            errorDetails = SERVER_ERRORS.find((e) => {
              return e.errorCode === error;
            });
            if (errorDetails === undefined) {
              setServerErrorMessage("There was an error submitting your application.");
            }

            field = findFieldInPage(errorDetails.sectionKey, page);
            field["error"] = true;
            if (onlyOne) {
              setServerErrorMessage(errorDetails.errorMessage);
            }
            break;
        }
      });
      if (typeof result["APPLICATIONID"] === "string") {
        setVRApplicationID(result["APPLICATIONID"]);
        setVRApplicationDate(result["APPLICATIONDATE"]);
      }
      if (result["MAILINAPPLICATIONID"]) {
        setMailInApplicationID(result["MAILINAPPLICATIONID"]);
        setMailInApplicationDate(result["MAILINAPPLICATIONDATE"]);
      }
      setSubmitLoading(false);
      return;
    }

    setSubmittedWithSigErrors(result["SIGNATURE"] === "Submitted Without Signature");
    setVRApplicationID(result["APPLICATIONID"]);
    setVRApplicationDate(result["APPLICATIONDATE"]);
    if (result["MAILINAPPLICATIONID"]) {
      setMailInApplicationID(result["MAILINAPPLICATIONID"]);
      setMailInApplicationDate(result["MAILINAPPLICATIONDATE"]);
    }
    setSubmitLoading(false);
    setCurrentPageIndex((prev) => Math.min(prev + 1, formFields.length - 1));
    clearHistory();
  };

  const onBack = () => {
    let incr = 1;
    if (currentPageSlug === "review" && (formData["no-penndot-number"] || !config.pages.vbm))
      incr = 2;
    setCurrentPageIndex((prev) => Math.max(prev - incr, config.startPage));
  };

  const onResetSubmit = () => {
    setFormData({});
    setCurrentPageIndex(config.startPage);
    setVRApplicationDate("");
    setVRApplicationID("");
  };

  const handleKeypress = (e) => {
    if (e.keyCode === 13) {
      onNextSubmit();
    }
  };

  const HeaderText = () =>
    moment() < moment(appJson.NextElection.NextElection)
      ? `Register by ${
          appJson.NextVRDeadline.NextVRDeadline
        } to vote in Pennsylvania's ${appJson.Text_OVRMailInElectionName.ElectionName.toLowerCase()} election on ${
          appJson.NextElection.NextElection
        }.`
      : "Register to vote in Pennsylvania.";

  const submitVRText = (
    <div className="thank-you">
      <p>
        Your voter registration application has been sent to your county voter registration office.
        Your application number is: {VRApplicationID}
      </p>
      {submittedWithSigErrors && formData["signatureimage"] > "" && (
        <p className="error">
          PA was not able to accept your signature. Please contact your{" "}
          <a href="https://www.vote.pa.gov/Resources/Pages/Contact-Your-Election-Officials.aspx">
            county voter registration office
          </a>{" "}
          for more information.{" "}
        </p>
      )}
      <p>
        Your application was submitted on {VRApplicationDate}. Your registration is not complete
        until processed and accepted by your county voter registration office. To check the status
        of your application, go to&nbsp;
        <a href="https://www.pavoterservices.pa.gov/pages/VoterApplicationStatus.aspx">
          Find Voter Application Status
        </a>{" "}
        and enter your application number, name, and date of birth.
      </p>
      <p>
        If your registration is accepted, you will receive a Voter Registration Card from your
        county by non-forwardable mail.
      </p>
      <p>
        If you do not receive your Voter Registration Card within 14 days, contact your county voter
        registration office.
      </p>
    </div>
  );

  // Set global listener for enter keydown
  useEffect(() => {
    window.addEventListener("keydown", handleKeypress);

    return () => {
      window.removeEventListener("keydown", handleKeypress);
    };
  }, [handleKeypress]);

  // Set global listener for submitloading to warn before leaving page
  useEffect(() => {
    const handler = (event) => {
      event.preventDefault();
      event.returnValue = "";
    };

    // if the form is NOT unchanged, then set the onbeforeunload
    if (submitLoading) {
      window.addEventListener("beforeunload", handler);
      // clean it up, if the dirty state changes
      return () => {
        window.removeEventListener("beforeunload", handler);
      };
    }

    return () => {};
  }, [submitLoading]);

  return (
    <>
      {ready ? (
        <div className="form-container">
          {!!currentPageIndex && !onConfirmationPage() && (
            <div>
              <div className="header">
                <div className="column">
                  <div className="title" style={{ display: "flex", alignItems: "center" }}>
                    <div style={{ flex: 1, marginRight: "8px" }}>
                      <a href={`https://${config.url}`}>
                        <img src={paLogo} alt="logo" className="paLogo" style={{ width: "100%" }} />
                      </a>
                    </div>
                    <div style={{ flex: 4, marginRight: "8px" }}>
                      <p>
                        {formData["_vbm_flow"] === "Yes"
                          ? l10n.getString("titles.voteSafeVBM")
                          : formData["_vbm_flow"] === "No"
                          ? l10n.getString("titles.voteSafeOVR")
                          : l10n.getString("titles.voteSafe")}
                      </p>
                    </div>
                  </div>
                  {config.startPage < currentPageIndex && currentPageIndex < formFields.length - 2 && (
                    <div className="status">
                      <div className="current" style={{ width: percentage, maxWidth: "100%" }}>
                        <div className="label">{percentage}</div>
                      </div>
                    </div>
                  )}
                </div>
              </div>
              <div className="page-title">
                <div className="column">
                  <span className="number">{currentPageIndex}</span> {page.title}
                </div>
              </div>
            </div>
          )}
          <div
            className="page-container"
            key={`page${currentPageIndex}`} // something quirky about this code and/or IE11 causes a bug with a strictly numeric key here -- adding "page" appears to work around the issue
            ref={pageContainer}
            tabIndex={0}>
            {!currentPageIndex && (
              <div className="header-home">
                <div className="column">
                  <img src={paLogo} alt="logo" className="paLogo" />
                  <div className="header-title">{l10n.getString("titles.heading")}</div>
                  <div className="header-body">
                    <HeaderText />
                  </div>
                </div>
              </div>
            )}
            {currentPageSlug === "confirmation" && (
              <div
                className="request-submitted"
                key={`page${currentPageIndex}`} // something quirky about this code and/or IE11 causes a bug with a strictly numeric key here -- adding "page" appears to work around the issue
                ref={pageContainer}
                tabIndex={0}>
                <div className="header-home">
                  <div className="column">
                    <h1>THANK YOU!</h1>
                    {formData["_vbm_flow"] === "No" &&
                      (formData["request-mail-ballot"] === "Yes"
                        ? !mailInBallotError
                          ? "Your voter registration application and mail-in ballot request have been submitted."
                          : "Your voter registration application has been submitted."
                        : "Your voter registration application has been submitted.")}
                    {formData["_vbm_flow"] === "Yes" &&
                      !mailInBallotError &&
                      `Your mail-in ballot application has been submitted`}
                  </div>
                </div>
                {(formData["request-mail-ballot"] === "Yes" || formData["_vbm_flow"]) &&
                  mailInBallotError && (
                    <div className="column">
                      <p className="error">
                        We were not able to submit your mail-in ballot request. Scroll down to read
                        more
                      </p>
                    </div>
                  )}
              </div>
            )}

            {!submitLoading && (
              <div className="column">
                {page.elements.map((element, index) => {
                  const { key, uniqueKey, handler, ...props } = element;
                  const Component = handlers[handler];
                  const showComponent = isElementActive(element, formData);

                  // this should delete form data if dependent fields are removed
                  // we handle mailin ballot and mailing address fields on the backend
                  // We need them to not be deleted
                  if (
                    !showComponent &&
                    key &&
                    !key.startsWith("mailin") &&
                    !key.startsWith("mailing-address") &&
                    !key === "penndot-number"
                  )
                    delete formData[key];

                  if (!onConfirmationPage()) {
                    props.value = formData[key] !== undefined ? formData[key] : props.value;
                  } else {
                    switch (uniqueKey) {
                      case "submit-VR":
                        props.value = submitVRText;
                        break;
                      case "submit-vbm":
                        props.value = ballotRequestSuccess({
                          MailInApplicationID: VRApplicationID,
                          MailInApplicationDate: VRApplicationDate,
                        });
                        break;
                      case "submit-mail-in":
                        props.value = ballotRequestSuccess({
                          MailInApplicationID,
                          MailInApplicationDate,
                        });
                        if (mailInBallotError) {
                          props.value = ballotRequestDeclined;
                        }
                        break;
                    }
                  }

                  const inputProps = key && {
                    forceValidation,
                    validator: props.optional ? () => false : defaultValidator,
                    name: key,
                    updateValue: (value, label = undefined) => {
                      if (key === "address-county") {
                        setFormData((prev) => {
                          let newState = Object.assign({}, prev);
                          delete newState["address-municipality"];
                          return newState;
                        });
                        if (value) {
                          //run when county is set but not when it's cleared
                          fetch(`/counties/${label}/municipalities`)
                            .then((res) => {
                              return res.json();
                            })
                            .then((munis) => {
                              const formFields = getFormFields(appJson, formData, munis);
                              setFormFields(formFields);
                              const p = formFields[currentPageIndex] || formFields.slice(-1)[0];
                              setPage(p);
                              setCurrentPageSlug(p.slug);
                            });
                        }
                      }
                      if (key === "_vbm_flow") {
                        const formFields = getFormFields(
                          appJson,
                          { ...formData, [key]: value },
                          [],
                        );
                        setFormFields(formFields);
                        const p = formFields[currentPageIndex] || formFields.slice(-1)[0];
                        setPage(p);
                        setCurrentPageSlug(p.slug);
                        setCurrentPageIndex(currentPageIndex + 1);
                      }
                      setFormData((prev) => ({ ...prev, [key]: value }));
                      if (props.copyTo) {
                        setFormData((prev) => ({ ...prev, [props.copyTo]: value }));
                      }
                    },
                  };

                  // This is needed so the mail in ballot autofill works
                  const reviewProps = true && {
                    formData,
                    formFields,
                    setCurrentPageIndex,
                  };
                  const { _dependency, _dependencyNot, _hideFn, ...safeProps } = props;

                  return (
                    Component &&
                    showComponent && (
                      <div className={"slidein-" + key} key={key || uniqueKey || index}>
                        <Component {...inputProps} {...reviewProps} {...safeProps} />
                      </div>
                    )
                  );
                })}
              </div>
            )}
            {submitLoading && (
              <div className="loading">
                <ReactLoading
                  className="submit-spinner"
                  type={"spin"}
                  color={"#1C4888"}
                  height={"20%"}
                  width={"15%"}
                />
                <p>
                  We are processing your registration. Please do not close this page until you
                  receive confirmation the registration was submitted. This may take up to 60
                  seconds.
                </p>
              </div>
            )}
          </div>
          {serverErrorMessage && <ServerError>{serverErrorMessage}</ServerError>}
          <div className="bottom-bar">
            {currentPageIndex > config.startPage &&
              !onConfirmationPage() &&
              !alreadySubmitted() &&
              !onErrorPage() && <BackButton onClick={onBack} style={{ flex: "1", fontSize: 28 }} />}
            {!onConfirmationPage() &&
              !alreadySubmitted() &&
              !onErrorPage() &&
              currentPageSlug !== "select-application-type" && (
                <NextButton
                  onClick={onNextSubmit}
                  onKeyPress={handleKeypress}
                  style={{ flex: "1 1 auto", fontSize: 28 }}
                  pageStatus={getPageStatus(formFields[currentPageIndex], formData)}
                  id={currentPageSlug}
                />
              )}
            {(onConfirmationPage() || alreadySubmitted() || onErrorPage()) && (
              <ResetButton
                onClick={onResetSubmit}
                onKeyPress={handleKeypress}
                style={{ flex: "1 1 auto", fontSize: 28 }}
              />
            )}
          </div>
        </div>
      ) : (
        <ReactLoading
          className="submit-spinner"
          type={"spin"}
          color={"#1C4888"}
          height={"20%"}
          width={"15%"}
        />
      )}
    </>
  );
}
