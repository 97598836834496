import React from "react";

// eslint-disable-next-line react/display-name
export default () => (
  <svg
    style={{ margin: 2, width: 24 }}
    xmlns="http://www.w3.org/2000/svg"
    width="30"
    height="30"
    viewBox="-3 -3 30 30">
    <path d="M9 22l-10-10.598 2.798-2.859 7.149 7.473 13.144-14.016 2.909 2.806z" />
  </svg>
);
