import l10n from "../localization";

// this regular expression accepts 2 formats:
// - MM/DD/YYYY (manual input)
// - YYYY-MM-DD (format from "date" inputs on supported browsers)
const dateRegex = /^(\d{1,2}\/\d{1,2}\/\d{4}|\d{4}-\d{2}-\d{2})$/;

export default (date, appJson) => {
  // calculate the date 18 years before next election day
  let minBirthday = new Date();
  let dateObj = new Date();
  if (appJson.NextElection.NextElection.includes("/")) {
    // some browsers may not interpret the MM/DD/YYYY date format correctly
    // need to manually convert it to date object
    const minDateComponents = appJson.NextElection.NextElection.split("/");
    const minMonth = parseInt(minDateComponents[0]) - 1; // month is indexed at 0 so we need to - 1
    const minDay = parseInt(minDateComponents[1]);
    const minYear = parseInt(minDateComponents[2]);
    minBirthday = new Date(minYear, minMonth, minDay);
  } else {
    minBirthday = new Date(appJson.NextElection.NextElection);
  }

  const currYear = minBirthday.getFullYear();
  minBirthday.setFullYear(currYear - 18);

  if (!dateRegex.test(date)) {
    // if date isn't in the right format
    return l10n.getString("fields.date.validationFormat");
  } else {
    if (date.includes("/")) {
      // some browsers may not interpret the MM/DD/YYYY date format correctly
      // need to manually convert it to date object
      const dateComponents = date.split("/");
      const month = parseInt(dateComponents[0]) - 1; // month is indexed at 0 so we need to - 1
      if (month > 11) {
        return l10n.getString("fields.date.validationMonth");
      }
      const day = parseInt(dateComponents[1]);
      if (day > 31) {
        return l10n.getString("fields.date.validationDay");
      }
      const year = parseInt(dateComponents[2]);
      if (year < 1900) {
        return l10n.getString("fields.date.validationYear");
      }
      dateObj = new Date(year, month, day);
    }
  }
  // check if date is at least 18 years ago
  return dateObj >= minBirthday && l10n.getString("fields.date.validationAge");
};
