import React from "react";

import l10n from "../services/localization";
import formatters from "../services/formatters";
import validators from "../services/validators";
import getNameFields from "./name-fields";
import getFields from "./get-fields";

import PENNDOT_FIELD from "./penndot-field";

export default function getVBMFields(appJson, munis = []) {
  return [
    {
      title: "Start Vote by Mail Application",
      slug: "start-application",
      elements: [
        {
          handler: "text",
          value:
            "If you have one, please have your PA driver's license or PennDOT ID available to reference as you complete the application.",
        },
        {
          handler: "text",
          value: (
            <div>
              <div className="card">
                <p>
                  Deadline Alert: Vote by mail applications must be received by{" "}
                  <strong>
                    {appJson.Text_OVRMailInApplnComplDate.Text_OVRMailInApplnComplDate}
                  </strong>
                  .
                </p>
                <p>
                  Mail-in ballots must be received by{" "}
                  <strong>
                    {appJson.Text_OVRMailInBallotRecvdDate.Text_OVRMailInBallotRecvdDate}
                  </strong>
                  .
                </p>
              </div>
            </div>
          ),
        },
      ],
    },
    // name page
    {
      title: l10n.getString("titles.name"),
      slug: "name",
      elements: [
        {
          dependency: ["name-changed"],
          handler: "text",
          header: "Registered Name",
          value: "Your name as it appears on your current voter registration",
        },
        ...getFields(getNameFields(appJson), {
          prefix: "previous-name",
          dependency: ["name-changed"],
        }),
        {
          dependency: ["name-changed"],
          handler: "text",
          header: "Updated Name",
          value: "The name you would like on your new voter registration",
        },
        ...getFields(getNameFields(appJson), { prefix: "name" }),
      ],
    },
    // page 5 - About You
    {
      title: "About You",
      slug: "about",
      elements: [
        {
          key: "date-of-birth",
          handler: "input",
          label: l10n.getString("labels.birthdate"),
          placeholder: "MM/DD/YYYY",
          // type: "date", // date input fields are too problematic across browsers, and using it somehow even made the "Next" button disappear in iOS
          validator: (v) => validators.date(v, appJson),
          mask: "99/99/9999",
        },
        {
          handler: "text",
          value: "Phone and email are optional, and used if information is missing on this form.",
        },
        {
          key: "phone-number",
          handler: "input",
          label: "Phone Number",
          optional: true,
          validator: validators.validateOptionalPhone,
          mask: "999-999-9999",
        },
        {
          key: "email",
          handler: "input",
          label: "Email Address",
          validator: validators.validateOptionalEmail,
          optional: true,
        },
      ],
    },
    // page 6
    {
      title: l10n.getString("titles.address"),
      slug: "address",
      elements: [
        {
          handler: "text",
          value: (
            <div>
              <p>Enter the address of your residence. You may not use a P.O. Box address.</p>
            </div>
          ),
        },
        {
          key: "address-street",
          handler: "input",
          label: "Street Address",
          validator: (address) => {
            if (address === "") {
              return "Your Street Address is required";
            }
          },
        },
        {
          key: "address-street-2",
          handler: "input",
          label: "Address Line 2",
          optional: true,
        },
        {
          key: "address-unit-type", // map to state API
          handler: "select",
          label: l10n.getString("labels.address.unitType"),
          options: appJson.UnitTypes.map((unitType) => ({
            value: unitType.UnitTypesCode,
            label: unitType.UnitTypesDescription,
          }))
            .filter((i) => i.value !== "TRLR")
            .sort((a, b) => {
              if (a.label > b.label) return 1;
              return -1;
            }),
          optional: true,
        },
        {
          key: "address-unit-number",
          handler: "input",
          label: "Unit #",
          optional: true,
        },
        {
          key: "address-city",
          handler: "input",
          label: "City",
          validator: (city) => {
            if (city === "") {
              return "Your City is required";
            }
          },
          copyTo: "mailin-ballot-res-address-city",
        },
        {
          key: "address-state",
          handler: "input",
          label: "State",
          defaultValue: "PA",
          readOnly: true,
          copyTo: "mailin-ballot-res-address-state",
        },
        {
          key: "address-zip",
          handler: "input",
          label: "Zip",
          validator: (zip) => {
            if (zip === "") {
              return "Your Zip Code is required";
            } else if (!validators.zipCode(zip, false)) {
              return "Please enter a valid 5 digit zip code";
            }
          },
          formatter: formatters.zipFormatterFive,
        },
        {
          key: "address-county",
          handler: "select",
          label: "County *",
          options: appJson.County.filter((x) => x.countyID !== "0").map((x) => {
            return {
              value: x.Countyname,
              label: x.Countyname,
            };
          }),
          validator: (county) => {
            if (county === "" || county === undefined) {
              return "Your County of residence is required";
            }
          },
        },
        {
          key: "address-municipality",
          handler: "select",
          label: "Municipality",
          options: munis
            .filter((x) => x.MunicipalityType !== "0")
            .map((x) => ({
              value: x.MunicipalityID,
              label: x.MunicipalityIDname,
            })),
          optional: true,
        },
        {
          uniqueKey: "municipality-explanation",
          handler: "text",
          value: (
            <p>
              If you don’t know what municipality you live in,{" "}
              <a
                href="https://munstats.pa.gov/Public/FindMunicipality.aspx"
                target="_blank"
                rel="noreferrer">
                you can look it up here.
              </a>
            </p>
          ),
        },
        {
          key: "lived-at-since",
          handler: "input",
          label: "I have lived at this address since",
          validator: (year) => {
            if (year === "") {
              return "Please provide the year you moved to this address.";
            }
          },
          mask: "9999",
        },
        {
          key: "same-mailing-address",
          handler: "radio",
          options: ["Yes", "No"],
          label: l10n.getString("labels.address.vbmMail"),
          validator: (val) => {
            if (val !== "Yes" && val !== "No") {
              return "Please select one";
            }
          },
          optional: false,
        },
        {
          hidefn: (formData) => !(formData["same-mailing-address"] === "No"),
          handler: "text",
          header: l10n.getString("text.address.mailingAddress"),
          value: l10n.getString("text.address.mail"),
        },
        {
          hidefn: (formData) => !(formData["same-mailing-address"] === "No"),
          key: "mailing-address-is-outside-usa",
          handler: "radio",
          label: "My mailing address is outside the USA.",
          options: ["Yes", "No"],
          validator: (val) => {
            if (val !== "Yes" && val !== "No") {
              return "Please select one";
            }
          },
        },
        {
          key: "mailing-address-country",
          handler: "input",
          label: "Country",
          hidefn: (formData) =>
            !(
              formData["same-mailing-address"] === "No" &&
              formData["mailing-address-is-outside-usa"] === "Yes"
            ),
          optional: true,
          scrollIntoView: true,
        },
        {
          key: "mailing-address-street",
          handler: "input",
          label: "Street Address",
          hidefn: (formData) => !(formData["same-mailing-address"] === "No"),
          validator: (address) => {
            if (address === "") {
              return "Your mailing street address is required.";
            }
          },
          scrollIntoView: true,
        },
        {
          key: "mailing-address-street-2",
          handler: "input",
          label: "Street Address 2",
          hidefn: (formData) => !(formData["same-mailing-address"] === "No"),
          optional: true,
          scrollIntoView: true,
        },
        {
          key: "mailing-address-unit-type", // map to state API
          handler: "select",
          label: l10n.getString("labels.address.unitType"),
          options: appJson.UnitTypes.map((unitType) => ({
            value: unitType.UnitTypesCode,
            label: unitType.UnitTypesDescription,
          }))
            .filter((i) => i.value !== "TRLR")
            .sort((a, b) => {
              if (a.label > b.label) return 1;
              return -1;
            }),
          optional: true,
          hidefn: (formData) => !(formData["same-mailing-address"] === "No"),
        },
        {
          key: "mailing-address-unit-number",
          handler: "input",
          label: "Unit #",
          optional: true,
          hidefn: (formData) => !(formData["same-mailing-address"] === "No"),
        },
        {
          key: "mailing-address-city",
          handler: "input",
          label: "City",
          hidefn: (formData) => !(formData["same-mailing-address"] === "No"),
          validator: (city) => {
            if (city === "") {
              return "Your mailing address city is required.";
            }
          },
        },
        {
          key: "mailing-address-state",
          hidefn: (formData) =>
            !(formData["same-mailing-address"] === "No") ||
            formData["mailing-address-is-outside-usa"] === "Yes",
          handler: "select",
          label: "State *",
          options: appJson.States.map((x) => {
            return {
              value: x.Code,
              label: x.CodesDescription,
            };
          }),
          copyTo: "mailin-ballot-mailing-address-state",
        },
        {
          key: "mailing-address-state-ex-us",
          hidefn: (formData) =>
            !(formData["same-mailing-address"] === "No") ||
            formData["mailing-address-is-outside-usa"] === "No",
          handler: "input",
          label: "State / Province",
        },
        {
          key: "mailing-address-zip",
          handler: "input",
          label: "Zip",
          hidefn: (formData) =>
            !(formData["same-mailing-address"] === "No") ||
            formData["mailing-address-is-outside-usa"] === "Yes",
          validator: (zip) => {
            if (zip === "") {
              return "Your mailing address zip code is required.";
            } else if (!validators.zipCode(zip, true)) {
              return "Please enter a valid 5 or 9 digit zip code.";
            }
          },
          formatter: formatters.zipFormatterNine,
        },
        {
          key: "mailing-address-postcode",
          handler: "input",
          label: "Postal Code",
          hidefn: (formData) =>
            !(formData["same-mailing-address"] === "No") ||
            formData["mailing-address-is-outside-usa"] === "No",
          validator: (val) => {
            if (!/^[0-9a-zA-Z ]+$/g.test(val)) return "Please enter an alphanumeric postal code.";
          },
        },
        {
          key: "mailing-address-is-my",
          handler: "input",
          label: "Mailing Address Is My … (e.g. second home)",
          hidefn: (formData) => !(formData["same-mailing-address"] === "No"),
        },
      ],
    },
    // page 11
    {
      title: "Annual Mail-in Ballot Request",
      slug: "mailin",
      elements: [
        {
          handler: "text",
          value: (
            <div>
              <div className="card">
                <div>
                  Deadline Alert: If you plan to vote by mail-in ballot in the{" "}
                  {appJson.Text_OVRMailInElectionName.ElectionName}, your completed application must
                  be received in the county office by {appJson.Text_OVRMailInApplnComplTime.Time} on{" "}
                  {appJson.Text_OVRMailInApplnComplDate.Text_OVRMailInApplnComplDate}. Your voted
                  ballot must be returned by {appJson.Text_OVRMailInBallotRecvdTime.RecvdTime} on{" "}
                  {appJson.Text_OVRMailInBallotRecvdDate.Text_OVRMailInBallotRecvdDate}.
                </div>
              </div>
            </div>
          ),
        },
        {
          handler: "text",
          value:
            "If you would like to receive mail-in ballots for the remainder of this year and if you would like to automatically receive an annual application for mail-in ballots each year, please indicate below.",
        },
        {
          handler: "text",
          value: (
            <div style={{ color: "#1C4888" }}>
              Do you want to receive mail-in ballots for the remainder of this year, and
              automatically receive an annual application for mail-in ballots each year?
            </div>
          ),
        },
        {
          key: "annual-mailin-request",
          handler: "radio",
          options: ["Yes", "No"],
          optional: true,
        },
        {
          handler: "text",
          value:
            "Please note: If you request an automatic mail-in ballot, you cannot vote as a regular voter in your polling place on election day. Instead, your choices to vote must be by delivering the ballot by mail or in person to your county elections office by 8:00 PM on election day, or if you cannot do so you may only vote by provisional ballot in your polling place on election day, unless you surrender your mail-in ballot and envelope to the judge of elections to be voided to vote by regular ballot.",
        },
        {
          handler: "text",
          value: (
            <div>
              <div>
                <strong>Identification</strong>
              </div>
              <p>{`Please provide your Pennsylvania Driver's license or PennDOT ID card number.`}</p>
            </div>
          ),
        },
        {
          ...PENNDOT_FIELD,
        },
        {
          handler: "text",
          value: (
            <div className="mail-in-ballot-label">
              <strong>Declaration</strong>
            </div>
          ),
        },
        {
          handler: "text",
          value: (
            <div
              dangerouslySetInnerHTML={{
                hidefn: (formData) =>
                  formData["request-mail-ballot"] !== "Yes" && formData["penndot-number"],
                __html: appJson.Text_OVRMailInApplnDeclaration.Text.replace(/\\t/, ""),
              }}></div>
          ),
        },
        {
          key: "mailin-declaration-agreement",
          handler: "checkbox",
          label: "I confirm that I have read and agree to the terms above.",
          value: false,
        },
      ],
    },
    // page 12
    {
      title: l10n.getString("titles.review"),
      slug: "review",
      elements: [
        {
          fieldGroups: [["name-title", "name-first", "name-middle", "name-last", "name-suffix"]],
          handler: "review",
          label: "Name",
          key: "name",
          keyField: "name-first",
        },
        {
          fieldGroups: [["previous-first-name", "previous-last-name"]],
          handler: "review",
          label: "Previous Name",
          key: "previousName",
          dependency: ["change-name"],
        },
        {
          fieldGroups: [["email"]],
          handler: "review",
          label: "Email Address",
          key: "email",
        },
        {
          fieldGroups: [["date-of-birth"]],
          handler: "review",
          label: "Date of Birth",
          key: "dob",
        },
        {
          fieldGroups: [["penndot-number"]],
          handler: "review",
          label: "PennDOT Number",
          key: "penndot",
          hidefn: (formData) => {
            return formData["no-penndot-number"];
          },
        },
        {
          fieldGroups: [["signatureimage"]],
          handler: "review",
          label: "Signature",
          key: "signatureimage",
          keyField: "signatureimage",
          hidefn: (formData) => !formData["signatureimage"],
        },
        {
          fieldGroups: [["social-4-digits"]],
          handler: "review",
          label: "SSN",
          key: "ssn",
          hidefn: (formData) => {
            return !formData["no-penndot-number"] || formData["no-dl-or-penndot"];
          },
        },
        {
          fieldGroups: [["phone-number"]],
          handler: "review",
          label: "Phone Number",
          key: "phone-number",
          hidefn: (formData) => {
            return formData["phone-number"] == null || formData["phone-number"] == "";
          },
        },
        {
          fieldGroups: [
            ["address-street"],
            ["address-street-2"],
            ["address-unit-type", "address-unit-number"],
            ["address-city", "address-state", "address-zip"],
            ["address-county"],
          ],
          handler: "review",
          label: "Home Address",
          key: "address",
        },
        {
          fieldGroups: [["lived-at-since"]],
          handler: "review",
          label: "Lived at since",
          key: "lived-at-since",
        },
        {
          fieldGroups: [["same-mailing-address"]],
          handler: "review",
          label: "Send ballot to home address",
          key: "same-mailing-address",
        },
        {
          fieldGroups: [["mailing-address-is-outside-usa"]],
          handler: "review",
          label: "Mailing address is outside the USA",
          key: "mailing-address-is-outside-usa",
          hidefn: (formData) => formData["same-mailing-address"] !== "No",
        },
        {
          fieldGroups: [
            ["mailing-address-street"],
            ["mailing-address-street-2"],
            ["mailing-address-unit-type", "mailing-address-unit-number"],
            ["mailing-address-city", "mailing-address-state", "mailing-address-zip"],
            ["mailing-address-state-ex-us", "mailing-address-postcode"],
            ["mailing-address-country"],
          ],
          handler: "review",
          label: "Mail-in Ballot Address",
          key: "mailinballot",
          hidefn: (formData) => formData["same-mailing-address"] !== "No",
        },
        {
          fieldGroups: [["mailing-address-is-my"]],
          handler: "review",
          label: "Mailing address is my:",
          key: "mailing-address-is-my",
          hidefn: (formData) => formData["same-mailing-address"] !== "No",
        },
      ],
    },
    {
      title: "Thanks",
      slug: "confirmation",
      elements: [
        {
          handler: "text",
          value: (
            <div>
              <hr />
              <h2>About Your Mail-In Ballot Request</h2>
            </div>
          ),
        },
        {
          uniqueKey: "submit-vbm",
          handler: "text",
          // Value is added in ./RegistrationFrom.js from ./ballot-request.js
        },
        {
          handler: "text",
          value: (
            <div>
              <div className="muted paid-for">
                <p>
                  Paid for by Tech for Campaigns,{" "}
                  <a href="https://techforcampaigns.org">techforcampaigns.org</a>. Not authorized by
                  candidate or candidate&apos;s committee.
                </p>
              </div>
              <div className="muted">
                <p>
                  <a
                    href="https://www.techforcampaigns.org/privacy"
                    target="_blank"
                    rel="noopener noreferrer">
                    {l10n.getString("privacy")}
                  </a>
                </p>
              </div>
            </div>
          ),
        },
      ],
    },
    {
      title: "Error",
      slug: "submit-error",
      elements: [
        {
          handler: "text",
          value: (
            <div>
              <h2>There was an error submitting your ballot application</h2>
            </div>
          ),
        },
        {
          handler: "text",
          value: (
            <div>
              <p>
                Your mail-in ballot application was not able to be completed at this time. Please
                use the{" "}
                <a
                  href="https://www.pa.gov/content/dam/copapwp-pagov/en/vote/resources/documents-and-forms/PADOS_MailInApplication.pdf"
                  target="_blank"
                  rel="noreferrer">
                  paper mail-in ballot application
                </a>{" "}
                and mail it to your County Election Office.
              </p>
            </div>
          ),
        },
        {
          handler: "text",
          value: (
            <div>
              <div className="muted paid-for">
                <p>
                  Paid for by Tech for Campaigns,{" "}
                  <a href="https://techforcampaigns.org">techforcampaigns.org</a>. Not authorized by
                  candidate or candidate&apos;s committee.
                </p>
              </div>
              <div className="muted">
                <p>
                  <a
                    href="https://www.techforcampaigns.org/privacy"
                    target="_blank"
                    rel="noopener noreferrer">
                    {l10n.getString("privacy")}
                  </a>
                </p>
              </div>
            </div>
          ),
        },
      ],
    },
  ];
}
