import React, { useEffect, useState } from "react";
import InputMask from "react-input-mask";
import { sanitizeProps } from "../RegistrationForm/handlers";
import l10n from "../../services/localization";

function Input({
  defaultValue = "",
  label,
  formatter = (v) => v,
  optional,
  tooltip = "",
  updateValue,
  validator = () => false,
  forceValidation = false,
  readOnly = false,
  scrollIntoView = false,
  mask = "",
  ...inputProps
}) {
  const [isValidating, setIsValidating] = useState(forceValidation);
  const [value, setValue] = useState(inputProps.value ?? defaultValue);

  const ref = React.useRef(null);

  useEffect(() => {
    if (scrollIntoView) {
      ref.current.focus();
      ref.current.scrollIntoView({
        behavior: "smooth",
        block: "center",
        inline: "nearest",
      });
    }
  }, [ref]);

  useEffect(() => {
    // add the defaultValue to the formData
    updateValue(value);
    // if there is an input value when component mounts, show validation
    value && setIsValidating(true);
  }, []);

  useEffect(() => {
    forceValidation && setIsValidating(true);
  }, [forceValidation]);

  const handleChange = (event) => {
    if (label === "state") {
      return;
    }
    const newValue = formatter(event.target.value);
    setValue(newValue);
    updateValue(newValue);
  };

  return (
    <label className={"label-container " + (isValidating && !!validator(value) ? "invalid" : "")}>
      <div aria-hidden className="label-flex">
        <div ref={ref}>
          {label} {optional ? `(${l10n.getString("labels.optional")})` : "*"}
        </div>
        {!!tooltip && (
          <div className="tooltip-button">
            <strong>?</strong>
            <div className="tooltip">{tooltip}</div>
          </div>
        )}
      </div>
      <div style={{ marginBottom: "25px" }}>
        <InputMask
          aria-invalid={!!validator(value)}
          aria-label={validator("") + ", " + tooltip}
          onBlur={() => setIsValidating(true)}
          readOnly={readOnly}
          onChange={handleChange}
          title={label}
          value={value}
          mask={mask}
          disabled={readOnly}
          {...sanitizeProps(inputProps)}
        />
      </div>
      <div aria-hidden className="validation">
        {validator(value)}&nbsp;
      </div>
    </label>
  );
}

export default Input;
