import l10n from "../services/localization";

export default function getNameFields(appJson) {
  return [
    {
      key: "first", // map to state API
      handler: "input",
      label: l10n.getString("labels.name.first"),
    },
    {
      key: "middle", // map to state API
      handler: "input",
      label: l10n.getString("labels.name.middle"),
      optional: true,
    },
    {
      key: "last", // map to state API
      handler: "input",
      label: l10n.getString("labels.name.last"),
    },
    {
      key: "suffix", // map to state API
      handler: "select",
      label: l10n.getString("labels.name.suffix"),
      options: appJson.Suffix.map((suffix) => ({
        value: suffix.NameSuffixCode,
        label: suffix.NameSuffixDescription,
      })).sort((a, b) => {
        const order = {
          J: 0,
          S: 1,
          I: 2,
          V: 3,
        };
        if (order[a.value[0]] < order[b.value[0]]) {
          return -1;
        } else if (order[a.value[0]] > order[b.value[0]]) {
          return 1;
        } else if (a.value <= b.value) {
          return -1;
        }
        return 1;
      }),
      optional: true,
    },
  ];
}
