import l10n from "../services/localization";
import validators from "../services/validators";
import formatters from "../services/formatters";

export default function getAddressFields(appJson) {
  return [
    {
      key: "address-street",
      handler: "input",
      label: "Street Address",
      validator: (address) => {
        if (address === "") {
          return "Your Street Address is required. If you cannot provide one, check the box at the end of this page indicating that you do not have a permanent address.";
        }
      },
      copyTo: "mailin-ballot-res-address-street",
    },
    {
      key: "address-street-2",
      handler: "input",
      label: "Address Line 2",
      optional: true,
      copyTo: "mailin-ballot-res-address-street-2",
    },
    {
      key: "address-unit-type", // map to state API
      handler: "select",
      label: l10n.getString("labels.address.unitType"),
      options: appJson.UnitTypes.map((unitType) => ({
        value: unitType.UnitTypesCode,
        label: unitType.UnitTypesDescription,
      }))
        .filter((i) => i.value !== "TRLR")
        .sort((a, b) => {
          if (a.label > b.label) return 1;
          return -1;
        }),
      optional: true,
      copyTo: "mailin-ballot-res-address-unit-type",
    },
    {
      key: "address-unit-number",
      handler: "input",
      label: "Unit #",
      optional: true,
      copyTo: "mailin-ballot-res-address-unit-number",
    },
    {
      key: "address-city",
      handler: "input",
      label: "City",
      validator: (city) => {
        if (city === "") {
          return "Your City is required. If you cannot provide one, check the box at the end of this page indicating that you do not have a permanent address.";
        }
      },
      copyTo: "mailin-ballot-res-address-city",
    },
    {
      key: "address-state",
      handler: "input",
      label: "State",
      defaultValue: "PA",
      readOnly: true,
      copyTo: "mailin-ballot-res-address-state",
    },
    {
      key: "address-zip",
      handler: "input",
      label: "Zip",
      validator: (zip) => {
        if (zip === "") {
          return "Your Zip Code is required. If you cannot provide one, check the box at the end of this page indicating that you do not have a permanent address.";
        } else if (!validators.zipCode(zip, false)) {
          return "Please enter a valid 5 digit zip code. If you cannot provide one, check the box at the end of this page indicating that you do not have a permanent address.";
        }
      },
      formatter: formatters.zipFormatterFive,
      copyTo: "mailin-ballot-res-address-zip",
    },
    {
      key: "address-county",
      handler: "select",
      label: "County *",
      options: appJson.County.filter((x) => x.countyID !== "0").map((x) => {
        return {
          value: x.Countyname,
          label: x.Countyname,
        };
      }),
      validator: (county) => {
        if (county === "" || county === undefined) {
          return "Your County of residence is required. If you cannot provide one, check the box at the end of this page indicating that you do not have a permanent address.";
        }
      },
    },
  ];
}
