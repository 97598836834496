import l10n from "../localization";

const phoneRegex = /^\d{3}-\d{3}-\d{4}$/;
const validateOptionalPhone = (phone) => {
  if (phone === "") {
    return false;
  }
  return !phoneRegex.test(phone) && l10n.getString("fields.phone.validation");
};

export default validateOptionalPhone;
