import LocalizedStrings from "localized-strings";

export default new LocalizedStrings(
  {
    en: {
      titles: {
        heading: "Pennsylvania Ballot Request",
        voteSafe: "Voter Registration or Mail-In Application Form",
        voteSafeOVR: "Voter Registration Form",
        voteSafeVBM: "Mail-in Ballot Request",
        subtitle: "Vote by mail in Pennsylvania’s primary election",
        email: "Email and Zip",
        eligibility: "Eligibility",
        name: "Name",
        address: "Address",
        identification: "Identification",
        additional: "Additional Info",
        review: "Review Your Information and Submit",
        declaration: "Declaration",
      },
      labels: {
        optional: "optional",
        email: "Email Address",
        emailUpdates:
          "Send email updates about my registration and upcoming elections from the PA Democratic Party",
        zip: "Zip Code",
        birthdate: "Date of Birth (mm/dd/yyyy)",
        citizenship: "Are you a citizen of the U.S.?",
        ageVerification: "Will you be 18 years or older on or before election day?",
        ssnMissing: "I don't have my Social Security Number",
        name: {
          changed: "I have changed my name since I last registered",
          first: "First Name",
          middle: "Middle Name",
          last: "Last Name",
          suffix: "Suffix",
          title: "Title",
        },
        address: {
          changed: "I have changed my address since I last registered",
          mail: "I get mail at a different address",
          vbmMail: "Should we mail the ballot to the above address?",
          unitType: "Unit Type",
        },
        phoneNumber: "Phone Number",
        mobilePhoneNumber: "Mobile Phone Number",
        race: "Race",
        gender: "Gender",
      },
      text: {
        instructions: "Register to vote in Pennsylvania's primary election on ",
        ssn: {
          required: "To complete this application you will need your Social Security Number.",
          missing:
            "To request a mail-in ballot without your Social Security Number, you will have to print and fill out a paper application to mail to your county elections office. Enter your email address below to have the application emailed to you.",
        },
        name: {
          instructions:
            "Please provide your name and other information as it appears on your PA Driver's License or PennDot ID card.",
          registered: {
            header: "Registered Name",
            instructions: "Your name as it appears on your current voter registration",
          },
          updated: {
            header: "Updated Name",
            instructions: "The name you would like on your new voter registration",
          },
        },
        address: {
          instructions: "Please provide your address as it appears on your voter registration.",
          oldAddress: "Old Address",
          changed: "Your address as it appears on your current voter registration",
          mailingAddress: "Mailing Address",
          // mail: "Where you would like your ballot mailed",
          newAddress: "New Address",
          new: "Where you would like to update your registration to",
          registeredAddress: "Registered Address",
          registered: "Your address as it appears on your voter registration",
        },
      },
      actions: {
        close: "Close",
        menu: "Menu",
        next: "Next",
        back: "Back",
        submit: "Submit",
        send: "Send",
        reset: "Start Over",
        fix: "please correct before moving to the next step",
      },
      deadline: "June 2",
      header: "Requesting a mail-in ballot takes less than two minutes.",
      fields: {
        date: {
          validationFormat: "Enter date in this format: MM/DD/YYYY",
          validationYear: "Enter a valid year.",
          validationMonth: "Enter a valid month.",
          validationDay: "Enter a valid day.",
          validationTooOld: "Enter a valid date",
          validationAge: "Must be 18 years of age before the next election date to register",
        },
        email: {
          label: "Email Address",
          tooltip: "Stay informed of important deadlines and upcoming elections.",
          validation: "Enter valid email address",
        },
        phone: {
          label: "Phone Number",
          tooltip: "Stay informed of important deadlines and upcoming elections.",
          validation: "Enter valid phone number",
        },
        zip: {
          label: "ZIP Code",
          tooltip: "Needed to verify your location.",
          validation: "Enter valid PA ZIP Code",
        },
      },
      required: "Required",
      continue: "Continue",
      aria_label: "Please {0} to continue",
      and: "and",
      paid_for:
        "Paid for by Tech for Campaigns, techforcampaigns.org. Not authorized by any candidate or candidate’s committee.",
      privacy: "Privacy",
    },
    es: {
      titles: {
        heading: "PA Registro de votantes en línea",
        voteSafe: "Formulario de registro de votantes",
      },
      subtitle: "Vote por correo en las elecciones primarias de Pensilvania",
      deadline: "el 2 de junio",
      header: "Solicitar una boleta por correo tarda menos de dos minutos.",
      fields: {
        email: {
          label: "Correo Electrónico",
          tooltip: "Manténgase informado de los plazos importantes y las próximas elecciones.",
          validation: "Ingrese una dirección de correo electrónico válida",
        },
        phone: {
          label: "Número de Teléfono",
          tooltip: "Manténgase informado de los plazos importantes y las próximas elecciones.",
          validation: "Ingrese un número de teléfono válido",
        },
        zip: {
          label: "Código Postal",
          tooltip: "Necesario para verificar su ubicación.",
          validation: "Ingrese un código postal válido de PA",
        },
      },
      required: "Obligatorio",
      continue: "Continuar",
      aria_label: "Por favor {0} para continuar",
      and: "y",
      privacy: "Privacidad",
    },
  },
  // { pseudo: true }
);
