const zipFormatterNine = (zip) => {
  zip = zip.replace(/[^\d-]/g, "");
  if (zip.match(/^\d{6,9}$/)) {
    return `${zip.substring(0, 5)}-${zip.substring(5)}`;
  }
  if (zip.endsWith("-____")) {
    return zip.substring(0, 5);
  }
  return zip;
};

const zipFormatterFive = (zip) => {
  zip = zip.replace(/\D/g, "");
  if (zip.length > 5) {
    return zip.substring(0, 5);
  }
  return zip;
};

export default {
  zipFormatterNine,
  zipFormatterFive,
};
