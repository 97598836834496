// prettier-ignore
const emailRegEx = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
const validateOptionalEmail = (email) => {
  if (email === "") {
    return false;
  }
  return (
    !emailRegEx.test(email.trim()) &&
    "The format of the email address is incorrect. Please correct and try again."
  );
};

export default validateOptionalEmail;
