import React from "react";

import l10n from "../services/localization";
import validators from "../services/validators";
import formatters from "../services/formatters";
import getVBMFields from "./vbm-fields";
import getOVRFields from "./ovr-fields";

export const findFieldInPage = (key, page) => {
  return page.elements.find((e) => {
    return e.key === key;
  });
};

export default function getFormFields(appJson, formData, munis = []) {
  let fields = [
    // page 0
    {
      title: l10n.getString("titles.email"),
      slug: "email-onboarding",
      elements: [
        {
          key: "email", // map to state API
          handler: "input",
          label: l10n.getString("labels.email"),
          type: "email",
          validator: validators.emailAddress,
        },
        {
          key: "phone-number",
          handler: "input",
          inputMode: "numeric",
          label: l10n.getString("labels.mobilePhoneNumber"),
          validator: validators.phoneNumber,
          mask: "999-999-9999",
        },
        {
          key: "address-zip", // map to state API
          handler: "input",
          inputMode: "numeric",
          label: l10n.getString("labels.zip"),
          validator: (zip) =>
            zip !== "" &&
            !validators.zipCode(zip, false) &&
            l10n.getString("fields.zip.validation"),
          formatter: formatters.zipFormatterFive,
          optional: true,
        },
        {
          key: "emailupdates",
          handler: "checkbox",
          label: l10n.getString("labels.emailUpdates"),
          optional: true,
          defaultValue: true,
        },
        {
          handler: "text",
          value: (
            <div>
              <div className="muted paid-for">
                <p>
                  Paid for by Tech for Campaigns,{" "}
                  <a href="https://techforcampaigns.org">techforcampaigns.org</a>. Not authorized by
                  candidate or candidate&apos;s committee.
                </p>
              </div>
              <div className="muted">
                <p>
                  <a
                    href="https://www.techforcampaigns.org/privacy"
                    target="_blank"
                    rel="noopener noreferrer">
                    {l10n.getString("privacy")}
                  </a>
                </p>
              </div>
            </div>
          ),
        },
      ],
    },
    // page 1
    {
      title: "Select type of application",
      slug: "select-application-type",
      elements: [
        {
          handler: "text",
          value: "Please choose one of the following options.",
        },
        {
          handler: "OVROrVBM",
          key: "_vbm_flow",
        },
      ],
    },
  ];
  if (formData["_vbm_flow"] === "Yes") {
    fields = [...fields, ...getVBMFields(appJson, munis)];
  } else {
    fields = [...fields, ...getOVRFields(appJson, munis)];
  }
  return fields;
}
