export default {
  dependencyNot: "no-penndot-number",
  key: "penndot-number", // map to state API
  handler: "input",
  label: "PennDOT Number",
  mask: "99999999",
  validator: (penndot) => {
    if (penndot === "") {
      return true && "Please enter your PennDOT number";
    } else if (isNaN(penndot)) {
      return "The PA driver's license or PennDOT ID card information you entered is not valid. Please confirm your PA driver's license or PennDOT ID card information.";
    }
  },
};
