import paZipCodes from "../pa-zip-codes";

const zipRegex = /^\d{5}((-?_{4})|(-?\d{4}))?$/; // allow 5-digit and zip+4 formats

export default (zip, anyState) => {
  const zipValid = zipRegex.test(zip);
  if (anyState) {
    return zipValid;
  }
  return zipValid && paZipCodes.includes(zip.substring(0, 5));
};
