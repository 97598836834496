import React, { useEffect, useState } from "react";
import classNames from "classnames";
import ReactSelect from "react-select";
import CheckmarkIcon from "../../icons/checkmark";
import ErrorIcon from "../../icons/error";

import Input from "../Input";
import SignatureBox from "../SignatureBox";
import OVROrVBM from "../OVROrVBM";
import l10n from "../../services/localization";

export function sanitizeProps(props) {
  const newProps = { ...props };
  delete newProps.formData;
  delete newProps.formFields;
  delete newProps.setCurrentPageIndex;
  delete newProps.hidefn;
  delete newProps.error;
  delete newProps.optional;

  return newProps;
}
const Checkbox = ({
  value = "",
  defaultValue = false,
  label,
  updateValue,
  validator,
  forceValidation,
  _optional,
  ...props
}) => {
  // default value of checkbox should be passed in through props
  const handleChange = ({ target: { _value, checked } }) => {
    updateValue(checked);
  };

  useEffect(() => {
    // add the defaultValue to the formData
    if (value === "") {
      updateValue(defaultValue);
    }
  }, []);

  return (
    <label className={forceValidation && !!validator(value) ? "invalid" : ""}>
      <div className="checkbox">
        <input
          checked={value !== "" ? value : defaultValue}
          onChange={handleChange}
          type="checkbox"
          {...sanitizeProps(props)}
        />{" "}
        <strong>{label}</strong>
        <div aria-hidden className="validation">
          {validator(value)}&nbsp;
        </div>
      </div>
    </label>
  );
};

const Radio = ({
  value = "",
  defaultValue,
  label,
  options,
  updateValue,
  validator,
  forceValidation,
  optional,
  mailIn,
  flex,
  ...props
}) => {
  const [isValidating, setIsValidating] = useState(forceValidation);

  const handleChange = ({ target: { value, checked } }) => {
    if (checked) {
      updateValue(value);
    }
    setIsValidating(true);
  };
  useEffect(() => {
    // add the defaultValue to the formData
    if (value === "") {
      updateValue(defaultValue);
    }
    value && setIsValidating(true);
  }, []);

  useEffect(() => {
    forceValidation && setIsValidating(true);
  }, [forceValidation]);

  return (
    <div
      className={`${isValidating && validator(value) ? "invalid" : ""} ${
        mailIn === true ? "mail-in" : ""
      }`}>
      <p className="radio-label">
        {label} {optional ? `(${l10n.getString("labels.optional")})` : "*"}
      </p>

      {options.map((option) => (
        <label
          className={`radio ${mailIn === true ? "mail-in-radio" : ""} ${
            flex === true ? "flex" : ""
          } ${isValidating && validator(value) ? "invalid" : ""}`}
          key={option}>
          <input
            checked={option === value}
            onChange={handleChange}
            type="radio"
            value={option}
            {...sanitizeProps(props)}
          />{" "}
          <strong>{option}</strong>
        </label>
      ))}
      <div aria-hidden className="validation">
        {validator(value)}
      </div>
    </div>
  );
};

const Review = ({
  fieldGroups = [],
  formData = [],
  valueToLabel = undefined,
  formFields,
  label,
  setCurrentPageIndex,
  error = false,
  keyField = undefined,
  dependency = undefined,
}) => {
  const pageIndex = formFields.findIndex(({ elements }) =>
    elements.find(({ key }) => key === (keyField ?? fieldGroups[0][0])),
  );

  const linkClickHandler = (e) => {
    e.preventDefault();
    setCurrentPageIndex(pageIndex);
  };

  if (dependency) {
    const dependencyMet = dependency.some((d) => {
      return !!formData[d];
    });

    if (!dependencyMet) {
      return null;
    }
  }

  return (
    <div className={classNames("review", { error })}>
      <span className="number">{error ? <ErrorIcon /> : <CheckmarkIcon />}</span>
      {label}
      <div className="value">
        {fieldGroups[0][0] === "signatureimage" && formData["signatureimage"] > "" && (
          <img src={formData["signatureimage"]} alt="Signature" style={{ maxWidth: "50%" }} />
        )}
        {fieldGroups[0][0] !== "signatureimage" &&
          fieldGroups.map((fieldRow, index) => (
            <div key={index}>
              {fieldRow
                .map((field) =>
                  valueToLabel && valueToLabel[formData[field]]
                    ? valueToLabel[formData[field]]
                    : formData[field],
                )
                .join(" ")}
            </div>
          ))}
      </div>
      <div className="edit">
        <a href="#" onClick={linkClickHandler}>
          Edit
        </a>
      </div>
    </div>
  );
};

const LinkToPage = ({ preLink, linkText, postLink, pageIndex, setCurrentPageIndex, error }) => {
  const linkClickHandler = (e) => {
    e.preventDefault();
    setCurrentPageIndex(pageIndex);
  };

  return (
    <div className={"text" + (error ? " error" : "")}>
      {preLink}&nbsp;
      <a href="#" onClick={linkClickHandler}>
        {linkText}
      </a>
      &nbsp;{postLink}
    </div>
  );
};

const Text = ({ header, value }) => (
  <div
    className="text"
    style={{
      borderBottom: !!header && "3px solid black",
      marginLeft: !!header && -13,
      marginRight: !!header && -13,
    }}>
    {!!header && (
      <div>
        <strong style={{ textTransform: "uppercase" }}>{header}</strong>
      </div>
    )}
    {value}
  </div>
);

const Button = ({ value, ...props }) => <button {...sanitizeProps(props)}>{value}</button>;

const Select = ({
  label,
  defaultValue,
  value,
  options,
  updateValue,
  validator,
  forceValidation,
  scrollIntoView = false,
  ...props
}) => {
  const onChange = (event) => {
    updateValue(event ? event.value : undefined, event ? event.label : undefined);
  };

  const ref = React.useRef(null);

  React.useEffect(() => {
    if (scrollIntoView) {
      ref.current.scrollIntoView({
        behavior: "smooth",
        block: "center",
        inline: "nearest",
      });
    }
  }, [ref]);

  const customStyles = {
    menuPortal: (base) => ({ ...base, zIndex: 9999 }), //https://github.com/JedWatson/react-select/issues/1085#issuecomment-616223517
    control: (provided) => ({
      ...provided,
      borderColor: forceValidation && !!validator(value) ? "#b8002c" : "#d2d7ff",
    }),
    placeholder: (provided) => ({
      ...provided,
      color: forceValidation && !!validator(value) ? "#b8002c" : "#1C4888",
    }),
    option: (provided) => ({
      ...provided,
      color: "#1C4888",
    }),
  };

  return (
    <div ref={ref} className={forceValidation && !!validator(value) ? "invalid" : ""}>
      <div style={{ margin: "0 -13px 25px -13px" }}>
        <ReactSelect
          isClearable={true}
          defaultValue={defaultValue}
          value={options.filter((x) => x.value === value)[0] || null}
          options={options}
          onChange={onChange}
          styles={customStyles}
          placeholder={label}
          menuPortalTarget={document.body}
          isDisabled={props.readOnly}
          {...sanitizeProps(props)}
        />
      </div>
      <div aria-hidden className="validation">
        {validator(value)}&nbsp;
      </div>
    </div>
  );
};

export default {
  button: Button,
  checkbox: Checkbox,
  input: Input,
  select: Select,
  radio: Radio,
  review: Review,
  text: Text,
  LinkToPage: LinkToPage,
  SignatureBox: SignatureBox,
  OVROrVBM: OVROrVBM,
};
