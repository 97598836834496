import date from "./date";
import emailAddress from "./email-address";
import phoneNumber from "./phone-number";
import zipCode from "./zip-code";
import radioButton from "./radio-button";
import validateOptionalEmail from "./optional-email";
import validateOptionalPhone from "./optional-phone";

export default {
  date,
  emailAddress,
  phoneNumber,
  zipCode,
  radioButton,
  validateOptionalEmail,
  validateOptionalPhone,
};
