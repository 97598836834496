import React from "react";

export function ballotRequestSuccess({ MailInApplicationID, MailInApplicationDate }) {
  return (
    <div className="thank-you">
      <p>
        Your ballot request form has been submitted. to your county elections office for review and
        processing. Your application number is: {MailInApplicationID}
      </p>
      <p>Your application was submitted on {MailInApplicationDate}.</p>
      <p>
        Your application is not complete until your county election office processes and accepts it.
        If your mail-in ballot application is accepted, your balloting materials will be mailed as
        soon as the ballot is available.
      </p>
      <h3>WHAT’S NEXT?</h3>
      <ul>
        <li>The county will review and process your application.</li>
        <li>If you provided an email, you’ll receive an update as soon as they process it.</li>
        <li>The county will mail your ballot as soon as it is available.</li>
        <li>
          Your ballot must be completed and must be received in the county office by 8:00 PM on
          Election Day. A late application will not be accepted, even if it was postmarked before
          the deadline.
        </li>
        <p>
          If you have any questions about your application, please contact your county election
          official{" "}
          <a href="https://www.votespa.com/Resources/Pages/Contact-Your-Election-Officials.aspx">
            found here
          </a>
          &nbsp;.
        </p>
      </ul>
    </div>
  );
}

export const ballotRequestDeclined = (
  <div className="thank-you">
    <p>
      We were unable to verify your PennDOT information at this time therefore we were{" "}
      <strong>not</strong> able to submit your mail-in ballot request online.{" "}
      <a href="https://www.vote.pa.gov/Voting-in-PA/Pages/Mail-and-Absentee-Ballot.aspx">
        Find information about appying for a mail-in ballot in person or by mail
      </a>
      .
    </p>
  </div>
);
