import getAddressFields from "./address-fields";

export default function getMailinBallotRedisdentAddressFields(appJson) {
  const fields = getAddressFields(appJson);
  return fields
    .map((field) => {
      // We're only copying values for address fielkds that have a mailin-ballot counterpart
      // So I can use that to filter the fields and exclude unecessary ones like County
      if (field.copyTo) {
        field.key = "mailin-ballot-res-" + field.key;
        field.hidefn = (formData) => !(formData["mail-in-ballot-options"] === "R");
        // This skips all the validation that a value was entered
        field.readOnly = true;
        return field;
      }
    })
    .filter((f) => f != null);
}
