import React from "react";

// eslint-disable-next-line react/display-name
export default function () {
  return (
    <svg
      height={32}
      style={{
        overflow: "visible",
        enableBackground: "new 0 0 32 32",
      }}
      width={32}
      xmlSpace="preserve"
      xmlns="http://www.w3.org/2000/svg">
      <circle
        cx={16}
        cy={16}
        r={16}
        style={{
          fill: "#d72828",
        }}
      />
      <path
        d="M14.5 25h3v-3h-3v3zm0-19v13h3V6h-3z"
        style={{
          fill: "#e6e6e6",
        }}
      />
    </svg>
  );
}
