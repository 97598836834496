import React from "react";
import moment from "moment";

import l10n from "../services/localization";
import validators from "../services/validators";
import formatters from "../services/formatters";
import getAddressFields from "./address-fields";
import getNameFields from "./name-fields";
import getMailinBallotRedisdentAddressFields from "./mailin-ballot-address-fields";
import getFields from "./get-fields";
import PENNDOT_FIELD from "./penndot-field";

export default function getOVRFields(appJson, munis = []) {
  return [
    {
      title: "Start Voter Registration Application",
      slug: "start-application",
      elements: [
        {
          handler: "text",
          value:
            "If you have one, please have your PA driver's license or PennDOT ID available to reference as you complete the application.",
        },
        {
          handler: "text",
          value: (
            <div>
              <div className="card">
                <p>
                  Deadline Alert: {appJson.NextVRDeadline.NextVRDeadline} is the last day to
                  register before the {appJson.NextElection.NextElection} election.
                </p>
              </div>
            </div>
          ),
        },
        {
          handler: "text",
          value: (
            <div>
              <p>
                If you are not a U.S. citizen and a resident of Pennsylvania at least 30 days before
                the next election, you CANNOT register to vote.
              </p>
              <p>If you have any questions, please call 1.877.VOTESPA (1.877.868.3772). </p>
              <p>
                Your registration is not complete until processed and accepted by your county voter
                registration office.
              </p>
              <p>
                To vote in the next election, you must complete your application by{" "}
                {appJson.NextVRDeadline.NextVRDeadline}. You can submit your application:
              </p>
              <ul>
                <li>On this web site.</li>
                <li>In person at your county’s voter registration office.</li>
                <li>
                  By mail to your county voter registration office. Mailed applications must be
                  received by 5:00 PM ET on {appJson.NextVRDeadline.NextVRDeadline}. A postmark
                  prior to the deadline is not enough.
                </li>
              </ul>
              <a
                href="https://www.vote.pa.gov/Resources/Documents/Voter_Registration_Application_English.pdf"
                target="_blank"
                rel="noreferrer">
                Click here to download a blank voter registration form
              </a>
              <p>
                If you are on active duty in the military, or you are a hospitalized or bedridden
                veteran, you can register at any time. See&nbsp;
                <a href="https://www.fvap.gov" target="_blank" rel="noreferrer">
                  www.fvap.gov
                </a>
                &nbsp;for more information.
              </p>
              <p>
                <a
                  href="https://www.vote.pa.gov/Voting-in-PA/Pages/Elections-FAQs.aspx"
                  target="_blank"
                  rel="noreferrer">
                  Frequently Asked Questions
                </a>
              </p>
            </div>
          ),
        },
      ],
    },
    // page 2
    {
      title: l10n.getString("titles.name"),
      slug: "name",
      elements: [
        {
          dependency: ["name-changed"],
          handler: "text",
          header: "Registered Name",
          value: "Your name as it appears on your current voter registration",
        },
        ...getFields(getNameFields(appJson), {
          prefix: "previous-name",
          dependency: ["name-changed"],
        }),
        {
          dependency: ["name-changed"],
          handler: "text",
          header: "Updated Name",
          value: "The name you would like on your new voter registration",
        },
        ...getFields(getNameFields(appJson), { prefix: "name" }),
      ],
    },
    // page 3
    {
      title: l10n.getString("titles.eligibility"),
      slug: "eligibility",
      elements: [
        {
          handler: "text",
          value: (
            <div>
              <p>If you answer &quot;No&quot; to either statement, you cannot register to vote.</p>
              <p>To register you must:</p>
              <ul>
                <li>
                  Be a <span className="bold underline">United States citizen</span> at least 30
                  days before the next election.
                </li>
                <li>
                  Be a <span className="underline">resident of Pennsylvania</span> and your election
                  district for at least 30 days before the next election.
                </li>
                <li>
                  Be <span className="underline">at least 18 years of age</span> on the day of the
                  next election.
                </li>
              </ul>
            </div>
          ),
        },
        {
          key: "us-citizen",
          handler: "radio",
          options: ["Yes", "No"],
          label: l10n.getString("labels.citizenship"),
          validator: validators.radioButton,
          flex: true,
        },
        {
          key: "18-or-older",
          handler: "radio",
          options: ["Yes", "No"],
          label: l10n.getString("labels.ageVerification"),
          validator: validators.radioButton,
          flex: true,
        },
      ],
    },
    // page 4
    {
      title: "Reason",
      slug: "reason",
      elements: [
        {
          page: "reason",
          handler: "text",
          value: (
            <div>
              <span className="bold">If you are currently registered to vote,</span> you do not have
              to use this application unless you have moved or changed your name.
            </div>
          ),
        },
        {
          page: "reason",
          handler: "text",
          value: <div className="radio-label"> Please check at least one box.</div>,
        },
        {
          page: "reason",
          key: "new-registration", // map to state API,
          handler: "checkbox",
          label: "New registration",
          value: false, // map to expected value in state API
          hidefn: (formData) => {
            return (
              formData["change-name"] || formData["change-address"] || formData["change-party"]
            );
          },
          validator: () => {
            return "";
          },
        },
        {
          page: "reason",
          key: "change-name", // map to state API,
          handler: "checkbox",
          label: "Change of name",
          value: false, // map to expected value in state API
          hidefn: (formData) => {
            return formData["new-registration"];
          },
          validator: () => {
            return "";
          },
        },
        {
          key: "previous-first-name",
          handler: "input",
          label: "Previous First Name",
          hidefn: (formData) => !(formData["change-name"] === true),
          scrollIntoView: true,
        },
        {
          key: "previous-middle-name",
          handler: "input",
          label: "Previous Middle Name",
          hidefn: (formData) => !(formData["change-name"] === true),
          optional: true,
        },
        {
          key: "previous-last-name",
          handler: "input",
          label: "Previous Last Name",
          hidefn: (formData) => !(formData["change-name"] === true),
        },
        {
          page: "reason",
          key: "change-address", // map to state API,
          handler: "checkbox",
          label: "Change of address",
          value: false, // map to expected value in state API
          hidefn: (formData) => {
            return formData["new-registration"];
          },
          validator: () => {
            return "";
          },
        },
        {
          handler: "text",
          value: (
            <p className="underline">
              If you have recently moved to Pennsylvania from another state, you must submit a new
              registration rather than a change of address.
            </p>
          ),
          hidefn: (formData) => !(formData["change-address"] === true),
          scrollIntoView: true,
        },
        {
          key: "previous-address",
          handler: "input",
          label: "Previous Address",
          validator: (address) => {
            // Custom validation message for page 4
            if (address === "") {
              return "Address of Previous Registration is required for an Address Change application";
            }
          },
          hidefn: (formData) => !(formData["change-address"] === true),
          scrollIntoView: true,
        },
        {
          key: "previous-city",
          handler: "input",
          label: "Previous City",
          validator: (city) => {
            // Custom validation message for page 4
            if (city === "") {
              return "City of Previous Registration is required for an Address Change application";
            }
          },
          hidefn: (formData) => !(formData["change-address"] === true),
        },
        {
          key: "previous-state",
          handler: "input",
          label: "State",
          defaultValue: "Pennsylvania",
          readOnly: true,
          hidefn: (formData) => !(formData["change-address"] === true),
        },
        {
          key: "previous-zip",
          handler: "input",
          label: "Previous Zip",
          validator: (zip) => {
            // Custom validation message for page 4
            if (zip === "") {
              return "Zip of Previous Registration is required for an Address Change application";
            } else if (!validators.zipCode(zip, false)) {
              return "Please enter a valid 5 digit zip code. If you cannot provide one, check the box at the end of this page indicating that you do not have a permanent address.";
            }
          },
          formatter: formatters.zipFormatterFive,
          hidefn: (formData) => !(formData["change-address"] === true),
        },
        {
          key: "previous-county",
          handler: "select",
          label: "Previous County *",
          options: appJson.County.filter((x) => x.countyID !== "0").map((x) => {
            return {
              value: x.Countyname,
              label: x.Countyname,
            };
          }),
          validator: (county) => {
            // Custom validation message for page 4
            if (county === "" || county === undefined) {
              return "Previous County Registration is required for an Address Change application";
            }
          },
          hidefn: (formData) => !(formData["change-address"] === true),
        },
        {
          page: "reason",
          key: "change-party", // map to state API,
          handler: "checkbox",
          label: "Change of party",
          value: false, // map to expected value in state API
          hidefn: (formData) => {
            return formData["new-registration"];
          },
          validator: () => {
            return "";
          },
        },
        {
          page: "reason",
          key: "fed-state-employee", // map to state API,
          handler: "checkbox",
          label: "Federal or State employee registering in county of last residence",
          value: false, // map to expected value in state API
          validator: () => {
            return "";
          },
        },
        {
          key: "voter-registration-number",
          handler: "input",
          label: "Voter Registration Number",
          hidefn: (formData) =>
            formData["change-name"] !== true &&
            formData["change-address"] !== true &&
            formData["change-party"] !== true,
          optional: true,
        },
        {
          key: "year-previous-registration",
          handler: "input",
          label: "Year of Previous Registration",
          hidefn: (formData) =>
            formData["change-name"] !== true &&
            formData["change-address"] !== true &&
            formData["change-party"] !== true,
          mask: "9999",
          validator: (year) => {
            if (year.includes("_") || moment().year() < parseInt(year)) {
              return "Please enter a valid year";
            }
          },
          optional: true,
        },
      ],
    },
    // page 5
    {
      title: "About You",
      slug: "about",
      elements: [
        {
          key: "date-of-birth",
          handler: "input",
          label: l10n.getString("labels.birthdate"),
          placeholder: "MM/DD/YYYY",
          // type: "date", // date input fields are too problematic across browsers, and using it somehow even made the "Next" button disappear in iOS
          validator: (v) => validators.date(v, appJson),
          mask: "99/99/9999",
        },
        {
          key: "gender",
          handler: "select",
          label: l10n.getString("labels.gender"),
          options: appJson.Gender.map((race) => ({
            value: race.GenderCode,
            label: race.GenderDescription,
          })),
          optional: true,
        },
        {
          key: "race",
          handler: "select",
          label: l10n.getString("labels.race"),
          options: appJson.Race.map((race) => ({
            value: race.RaceCode,
            label: race.RaceDescription,
          })),
          optional: true,
        },
        {
          handler: "text",
          value: "Phone and email are optional, and used if information is missing on this form.",
        },
        {
          key: "phone-number",
          handler: "input",
          label: "Phone Number",
          optional: true,
          validator: validators.validateOptionalPhone,
          mask: "999-999-9999",
        },
        {
          key: "email",
          handler: "input",
          label: "Email Address",
          validator: validators.validateOptionalEmail,
          optional: true,
        },
        {
          handler: "text",
          value:
            "Would you like to be a poll worker? If you check either of these boxes, your county voter registration office will contact you. (optional)",
        },
        {
          key: "poll-worker",
          handler: "checkbox",
          label: "I would like to be a poll worker on Election Day",
          optional: true,
          value: false,
        },
        {
          key: "bilingual-interpreter",
          handler: "checkbox",
          label: "I would like to be a bilingual interpreter on Election Day",
          optional: true,
          value: false,
        },
        {
          dependency: ["bilingual-interpreter"],
          key: "bilingual-language",
          handler: "input",
          label: "I speak this language",
          placeholder: "Interpreter Language",
        },
        {
          key: "annual-ballot-request",
          handler: "checkbox",
          label:
            "By checking the box, you are requesting that you continue to maintain your annual ballot request status when updating your address.",
          optional: true,
          value: false,
        },
      ],
    },
    // page 6
    {
      title: l10n.getString("titles.address"),
      slug: "address",
      elements: [
        {
          handler: "text",
          value: (
            <div>
              <p>Enter the address of your residence. You may not use a P.O. Box address.</p>
              <p>
                If you live in a rural area or are homeless and do not have a street address or a
                permanent residence, please use{" "}
                <a
                  href="https://www.vote.pa.gov/Resources/Documents/Voter_Registration_Application_English.pdf"
                  target="_blank"
                  rel="noreferrer">
                  this link
                </a>{" "}
                to print a blank voter registration application, use the map on the printed form to
                show where you live or spend most of your time, and mail it to your county voter
                registration office.
              </p>
            </div>
          ),
        },
        ...getAddressFields(appJson),
        {
          key: "address-municipality",
          handler: "select",
          label: "Municipality",
          options: munis
            .filter((x) => x.MunicipalityType !== "0")
            .map((x) => ({
              value: x.MunicipalityID,
              label: x.MunicipalityIDname,
            })),
          optional: true,
        },
        {
          uniqueKey: "municipality-explanation",
          handler: "text",
          value: (
            <p>
              If you don’t know what municipality you live in,{" "}
              <a
                href="https://munstats.pa.gov/Public/FindMunicipality.aspx"
                target="_blank"
                rel="noreferrer">
                you can look it up here.
              </a>
            </p>
          ),
        },
        {
          key: "no-address",
          handler: "checkbox",
          label: "I do not have a street address or permanent residence",
          optional: true,
          value: false,
          defaultValue: false,
        },
        {
          dependency: ["no-address"],
          key: "no-address-selected",
          handler: "text",
          header: <div className="error-strong">ERROR</div>,
          value: (
            <div className="error">
              A complete mailing or residential address is required for your application to be
              submitted online. Please use the link at the top of the page to print a blank voter
              registration application. Please complete, sign, and date it then mail it to your
              county voter registration office. Do not FAX your application form.
            </div>
          ),
        },
        {
          key: "different-mailing-address",
          handler: "checkbox",
          label: l10n.getString("labels.address.mail"),
          optional: true,
          value: false,
        },
        {
          dependency: ["different-mailing-address"],
          handler: "text",
          header: l10n.getString("text.address.mailingAddress"),
          value: l10n.getString("text.address.mail"),
        },
        {
          key: "mailing-address-street",
          handler: "input",
          label: "Street Address",
          dependency: ["different-mailing-address"],
          validator: (address) => {
            if (address === "") {
              return "Your Street Address is required. If you cannot provide one, check the box at the end of this page indicating that you do not have a permanent address.";
            }
          },
          scrollIntoView: true,
          copyTo: "mailin-ballot-mailing-address-street",
        },
        {
          key: "mailing-address-city",
          handler: "input",
          label: "City",
          dependency: ["different-mailing-address"],
          validator: (city) => {
            if (city === "") {
              return "Your City is required. If you cannot provide one, check the box at the end of this page indicating that you do not have a permanent address.";
            }
          },
          copyTo: "mailin-ballot-mailing-address-city",
        },
        {
          key: "mailing-address-state",
          dependency: ["different-mailing-address"],
          handler: "select",
          label: "State *",
          options: appJson.States.map((x) => {
            return {
              value: x.Code,
              label: x.CodesDescription,
            };
          }),
          copyTo: "mailin-ballot-mailing-address-state",
        },
        {
          key: "mailing-address-zip",
          handler: "input",
          label: "Zip",
          dependency: ["different-mailing-address"],
          validator: (zip) => {
            if (zip === "") {
              return "Your Zip Code is required. If you cannot provide one, check the box at the end of this page indicating that you do not have a permanent address.";
            } else if (!validators.zipCode(zip, true)) {
              return "Please enter a valid 5 or 9 digit zip code. If you cannot provide one, check the box at the end of this page indicating that you do not have a permanent address.";
            }
          },
          formatter: formatters.zipFormatterNine,
          copyTo: "mailin-ballot-mailing-address-zip",
        },
      ],
    },
    // page 7
    {
      title: l10n.getString("titles.identification"),
      slug: "identification",
      elements: [
        {
          handler: "text",
          value:
            "If you have a PA driver's license or PennDOT ID card number, you must use it.  Your PennDOT number is the 8 digit number found on the top right of a PA driver's license.",
        },
        { ...PENNDOT_FIELD },
        {
          key: "no-penndot-number",
          handler: "checkbox",
          label: "I do not have a PA Driver's License or a PennDOT ID card.",
          optional: true,
          value: false,
        },
        {
          dependency: ["no-penndot-number"],
          handler: "text",
          value:
            "If you do not have a PennDOT number please enter the last 4 digits of your Social Security number and submit a signature.",
        },
        {
          dependency: ["no-penndot-number"],
          key: "social-4-digits", // map to state API
          handler: "input",
          label: "Last 4 digits of your social security #",
          mask: "9999",
          validator: (ssn) => {
            if (ssn === "") {
              return true && "Please enter your Social Security number";
            } else if (isNaN(ssn)) {
              return "Please enter the LAST FOUR digits of your Social Security number.";
            }
          },
          hidefn: (formData) => formData["no-dl-or-penndot"],
        },
        {
          key: "no-dl-or-penndot",
          handler: "checkbox",
          dependency: ["no-penndot-number"],
          optional: true,
          label:
            "I do not have a PA driver's license or PennDOT ID card or a Social Security number.",
        },
        {
          handler: "text",
          value: (
            <div>
              <p>Please press the button below to add a signature.</p>
            </div>
          ),
        },
        {
          key: "signatureimage", // map to state API
          handler: "SignatureBox",
        },
      ],
    },
    // page 8
    {
      title: "Political Party",
      slug: "party",
      elements: [
        {
          handler: "text",
          value:
            "To vote in a primary, you must register with either the Democratic or Republican party.",
        },
        {
          key: "political-party",
          handler: "select",
          label: "Political Party *",
          options: appJson.PoliticalParty.map((politicalParty) => ({
            value: politicalParty.PoliticalPartyCode,
            label: politicalParty.PoliticalPartyDescription,
          })),
        },
        {
          key: "other-political-party",
          handler: "input",
          label: "Political Party Name",
          hidefn: (formData) => !(formData["political-party"] === "OTH"),
        },
      ],
    },
    // page 9
    {
      title: "Voting Assistance (optional)",
      slug: "voting-assistance",
      elements: [
        // {
        //   key: "vote-safe-sms-messages",
        //   handler: "checkbox",
        //   label: "Send me SMS messages from Vote Safe",
        //   value: "yes",
        // },
        {
          handler: "text",
          value: "(Optional) If you require assistance with voting, complete this section.",
        },
        {
          key: "require-voting-assistance",
          handler: "checkbox",
          label: "I will require assistance with voting",
          optional: true,
          value: false,
        },
        {
          dependency: ["require-voting-assistance"],
          handler: "text",
          header: "Voting Assistance",
          value: "If you require assistance with voting, complete this section.",
        },
        {
          dependency: ["require-voting-assistance"],
          key: "kind-of-assistance",
          handler: "select",
          label: "I need this kind of assistance:",
          options: appJson.AssistanceType.map((assistanceType) => ({
            value: assistanceType.AssistanceTypeCode,
            label: assistanceType.AssistanceTypeDescription,
          })),
          scrollIntoView: true,
        },
        {
          key: "preferred-language",
          handler: "input",
          label: "My preferred language",
          // LN is the code for language assistance
          hidefn: (formData) => !(formData["kind-of-assistance"] === "LN"),
          scrollIntoView: true,
        },
        {
          key: "someone-helped",
          handler: "checkbox",
          label: "Someone helped me with this form",
          optional: true,
          value: false,
        },
        {
          dependency: ["someone-helped"],
          handler: "text",
          header: "Help with this form",
          value: "If someone helped you with this form, fill in the information in this section.",
        },
        {
          dependency: ["someone-helped"],
          key: "helper-name",
          handler: "input",
          label: "Helper Name",
          scrollIntoView: true,
        },
        {
          dependency: ["someone-helped"],
          key: "helper-address",
          handler: "input",
          label: "Helper Address",
        },
        {
          dependency: ["someone-helped"],
          key: "helper-phone",
          handler: "input",
          label: "Helper Phone",
          mask: "999-999-9999",
        },
        {
          dependency: ["someone-helped"],
          handler: "text",
          value: (
            <div
              dangerouslySetInnerHTML={{
                __html: appJson.Text_OVRApplnAssistanceDeclaration.Text,
              }}></div>
          ),
        },
        {
          dependency: ["someone-helped"],
          key: "helper-agreement",
          handler: "checkbox",
          label: "I confirm that I have read and agree to the terms above",
          value: false,
        },
      ],
    },
    // page 10
    {
      title: "Declaration",
      slug: "declaration",
      elements: [
        {
          handler: "text",
          value: (
            <div
              dangerouslySetInnerHTML={{
                __html: appJson.Text_OVRApplnDeclaration.Text.replace(/\\t/, "") // OVR API has invalid html
                  // TODO: remove this "override" once the API has been updated.
                  .replace("section 5", "section 6"),
              }}></div>
          ),
        },
        {
          uniqueKey: "declaration-warning",
          handler: "text",
          value: (
            <div className="card" style={{ marginBottom: "60px" }}>
              <p>
                <strong>Penalty for Falsifying Declaration</strong>
              </p>
              <p>
                <small>
                  WARNING: If a person signs an official registration application knowing a
                  statement declared in the application to be false, makes a false registration, or
                  furnishes false information, the person commits perjury. Perjury is punishable,
                  upon conviction, by a term of imprisonment not exceeding seven years, or a fine
                  not exceeding $15,000, or both, at the discretion of the court.<br></br>
                  <br></br>Submitting an application containing false information may also subject a
                  person to other penalties, including loss of the right of suffrage, under state or
                  federal law.
                </small>
              </p>
            </div>
          ),
        },
        {
          key: "declaration-agreement",
          handler: "checkbox",
          label: "I confirm that I have read and agree to the terms above.",
          value: false,
        },
      ],
    },
    // page 11
    {
      title: "Mail-in Ballot (optional)",
      slug: "mailin",
      elements: [
        {
          handler: "text",
          value: (
            <div>
              <div className="card">
                <div>
                  Deadline Alert: If you plan to vote by mail-in ballot in the{" "}
                  {appJson.Text_OVRMailInElectionName.ElectionName}, your completed application must
                  be received in the county office by {appJson.Text_OVRMailInApplnComplTime.Time} on{" "}
                  {appJson.Text_OVRMailInApplnComplDate.Text_OVRMailInApplnComplDate}. Your voted
                  ballot must be returned by {appJson.Text_OVRMailInBallotRecvdTime.RecvdTime} on{" "}
                  {appJson.Text_OVRMailInBallotRecvdDate.Text_OVRMailInBallotRecvdDate}.
                </div>
              </div>
            </div>
          ),
        },
        {
          handler: "text",
          value:
            "If you would like to receive mail-in ballots for the remainder of this year and if you would like to automatically receive an annual application for mail-in ballots each year, please indicate below.",
        },
        {
          key: "request-mail-ballot",
          label: "Would you like to receive mail-in ballots for any election you qualify for?",
          handler: "radio",
          options: ["Yes", "No"],
          defaultValue: "No",
          optional: true,
        },
        {
          handler: "LinkToPage",
          preLink:
            "You need to have a PA Driver’s License or PennDOT ID number to apply with this online form. Please",
          linkText: "go back",
          postLink: (
            <div>
              and update your PennDOT ID, or download the&nbsp;
              <a
                href="https://www.pa.gov/content/dam/copapwp-pagov/en/vote/resources/documents-and-forms/PADOS_MailInApplication.pdf"
                target="_blank"
                rel="noreferrer">
                mail-in ballot paper application
              </a>
              &nbsp;and mail it to your County Board of Elections.
            </div>
          ),
          pageIndex: 7,
          error: true,
          hidefn: (formData) =>
            !(formData["request-mail-ballot"] === "Yes" && !formData["penndot-number"]),
        },
        {
          handler: "text",
          value:
            "Please note: If you request an automatic mail-in ballot, you cannot vote as a regular voter in your polling place on election day. Instead, your choices to vote must be by delivering the ballot by mail or in person to your county elections office by 8:00 PM on election day, or if you cannot do so you may only vote by provisional ballot in your polling place on election day, unless you surrender your mail-in ballot and envelope to the judge of elections to be voided to vote by regular ballot.",
        },
        {
          key: "mail-in-ballot-options",
          handler: "select",
          label: "Mail ballot to me at the following address *",
          options: appJson.MailinAddressTypes.map((mailinAddressType) => ({
            value: mailinAddressType.MailinAddressTypesCode,
            label: mailinAddressType.MailinAddressTypesDescription,
          })),
          scrollIntoView: true,
          hidefn: (formData) =>
            !(formData["request-mail-ballot"] === "Yes" && formData["penndot-number"]),
        },
        {
          handler: "LinkToPage",
          preLink: "Your address provided below will be used for your Mail-In Ballot Application.",
          linkText: "Click here to update.",
          pageIndex: 6,
          hidefn: (formData) =>
            !(formData["mail-in-ballot-options"] === "R") &&
            !(formData["mail-in-ballot-options"] === "M"),
        },
        ...getMailinBallotRedisdentAddressFields(appJson),
        {
          key: "mailin-ballot-mailing-address-street",
          handler: "input",
          label: "Street Address",
          validator: (address) => {
            if (address === "") {
              return "Your Street Address is required. If you cannot provide one, check the box at the end of this page indicating that you do not have a permanent address.";
            }
          },
          scrollIntoView: true,
          hidefn: (formData) => !(formData["mail-in-ballot-options"] === "M"),
        },
        {
          key: "mailin-ballot-mailing-address-city",
          handler: "input",
          label: "City",
          validator: (city) => {
            if (city === "") {
              return "Your City is required. If you cannot provide one, check the box at the end of this page indicating that you do not have a permanent address.";
            }
          },
          hidefn: (formData) => !(formData["mail-in-ballot-options"] === "M"),
        },
        {
          key: "mailin-ballot-mailing-address-state",
          handler: "select",
          label: "State",
          validator: (state) => {
            if (state === "") {
              return "Your State is required. If you cannot provide one, check the box at the end of this page indicating that you do not have a permanent address.";
            }
          },
          options: appJson.States.map((x) => {
            return {
              value: x.Code,
              label: x.CodesDescription,
            };
          }),
          copyTo: "mailin-ballot-mailing-address-state",
          hidefn: (formData) => !(formData["mail-in-ballot-options"] === "M"),
        },
        {
          key: "mailin-ballot-mailing-address-zip",
          handler: "input",
          label: "Zip",
          validator: (zip) => {
            if (zip === "") {
              return "Your Zip Code is required. If you cannot provide one, check the box at the end of this page indicating that you do not have a permanent address.";
            } else if (!validators.zipCode(zip, true)) {
              return "Please enter a valid 5 or 9 digit zip code. If you cannot provide one, check the box at the end of this page indicating that you do not have a permanent address.";
            }
          },
          formatter: formatters.zipFormatterNine,
          hidefn: (formData) => !(formData["mail-in-ballot-options"] === "M"),
        },
        {
          handler: "text",
          value: (
            <div className="step-6-new-address">
              <h2>Mailing Address</h2>
              <p>Where you would like your ballot mailed</p>
            </div>
          ),
          hidefn: (formData) => !(formData["mail-in-ballot-options"] === "A"),
        },
        {
          key: "mail-in-ballot-address-street",
          handler: "input",
          label: "Street Address",
          hidefn: (formData) => !(formData["mail-in-ballot-options"] === "A"),
          validator: (address) => {
            if (address === "") {
              return "Your Street Address is required. If you cannot provide one, check the box at the end of this page indicating that you do not have a permanent address.";
            }
          },
        },
        {
          key: "mail-in-ballot-address-city",
          handler: "input",
          label: "City",
          hidefn: (formData) => !(formData["mail-in-ballot-options"] === "A"),
          validator: (city) => {
            if (city === "") {
              return "Your City is required. If you cannot provide one, check the box at the end of this page indicating that you do not have a permanent address.";
            }
          },
        },
        {
          key: "mail-in-ballot-address-state",
          handler: "select",
          label: "State",
          hidefn: (formData) => !(formData["mail-in-ballot-options"] === "A"),
          validator: (state) => {
            if (state === "") {
              return "Your State is required. If you cannot provide one, check the box at the end of this page indicating that you do not have a permanent address.";
            }
          },
          options: appJson.States.map((x) => {
            return {
              value: x.Code,
              label: x.CodesDescription,
            };
          }),
        },
        {
          key: "mail-in-ballot-address-zip",
          handler: "input",
          label: "Zip",
          hidefn: (formData) => !(formData["mail-in-ballot-options"] === "A"),
          validator: (zip) => {
            if (zip === "") {
              return "Your Zip Code is required. If you cannot provide one, check the box at the end of this page indicating that you do not have a permanent address.";
            } else if (!validators.zipCode(zip, true)) {
              return "Please enter a valid 5 or 9 digit zip code. If you cannot provide one, check the box at the end of this page indicating that you do not have a permanent address.";
            }
          },
          formatter: formatters.zipFormatterNine,
        },
        {
          key: "ward",
          handler: "input",
          label: "Please indicate your Ward or Voting District",
          optional: true,
          hidefn: (formData) =>
            !(formData["request-mail-ballot"] === "Yes" && formData["penndot-number"]),
        },
        {
          key: "lived-at-since",
          handler: "input",
          label: "I have lived at this address since",
          hidefn: (formData) =>
            !(formData["request-mail-ballot"] === "Yes" && formData["penndot-number"]),
          validator: (year) => {
            if (year === "") {
              return "Please provide the year you moved to this address.";
            }
          },
          mask: "9999",
        },
        {
          handler: "text",
          value: (
            <div>
              <div>
                <strong>Identification</strong>
              </div>
              <p>
                Your PA driver’s license or PennDOT ID card number provided before will be used for
                your Mail-In Ballot Application.
              </p>
            </div>
          ),
          hidefn: (formData) =>
            !(formData["request-mail-ballot"] === "Yes" && formData["penndot-number"]),
        },
        {
          ...PENNDOT_FIELD,
          readOnly: true,
          validator: undefined,
          hidefn: (formData) => !(formData["request-mail-ballot"] === "Yes"),
        },
        {
          handler: "text",
          value: (
            <div className="mail-in-ballot-label">
              <strong>Declaration</strong>
            </div>
          ),
          hidefn: (formData) =>
            formData["request-mail-ballot"] !== "Yes" || !formData["penndot-number"],
        },
        {
          handler: "text",
          value: (
            <div
              dangerouslySetInnerHTML={{
                hidefn: (formData) =>
                  formData["request-mail-ballot"] !== "Yes" && formData["penndot-number"],
                __html: appJson.Text_OVRMailInApplnDeclaration.Text.replace(/\\t/, ""),
              }}></div>
          ),
          hidefn: (formData) =>
            formData["request-mail-ballot"] !== "Yes" || !formData["penndot-number"],
        },
        {
          key: "mailin-declaration-agreement",
          handler: "checkbox",
          label: "I confirm that I have read and agree to the terms above.",
          value: false,
          hidefn: (formData) =>
            formData["request-mail-ballot"] !== "Yes" || !formData["penndot-number"],
        },
      ],
    },
    // page 12
    {
      title: l10n.getString("titles.review"),
      slug: "review",
      elements: [
        {
          fieldGroups: [["name-title", "name-first", "name-middle", "name-last", "name-suffix"]],
          handler: "review",
          label: "Name",
          key: "name",
          keyField: "name-first",
        },
        {
          fieldGroups: [["previous-first-name", "previous-last-name"]],
          handler: "review",
          label: "Previous Name",
          key: "previousName",
          dependency: ["change-name"],
        },
        {
          fieldGroups: [["email"]],
          handler: "review",
          label: "Email Address",
          key: "email",
        },
        {
          fieldGroups: [["date-of-birth"]],
          handler: "review",
          label: "Date of Birth",
          key: "dob",
        },
        {
          fieldGroups: [["penndot-number"]],
          handler: "review",
          label: "PennDOT Number",
          key: "penndot",
          hidefn: (formData) => {
            return formData["no-penndot-number"];
          },
        },
        {
          fieldGroups: [["signatureimage"]],
          handler: "review",
          label: "Signature",
          key: "signatureimage",
          keyField: "signatureimage",
          hidefn: (formData) => !formData["signatureimage"],
        },
        {
          fieldGroups: [["social-4-digits"]],
          handler: "review",
          label: "SSN",
          key: "ssn",
          hidefn: (formData) => {
            return !formData["no-penndot-number"] || formData["no-dl-or-penndot"];
          },
        },
        {
          fieldGroups: [["phone-number"]],
          handler: "review",
          label: "Phone Number",
          key: "phone-number",
          hidefn: (formData) => {
            return formData["phone-number"] == null || formData["phone-number"] == "";
          },
        },
        {
          fieldGroups: [
            ["address-street"],
            ["address-street-2"],
            ["address-unit-type", "address-unit-number"],
            ["address-city", "address-state", "address-zip"],
            ["address-county"],
          ],
          handler: "review",
          label: "Home Address",
          key: "address",
        },
        {
          fieldGroups: [
            ["mailin-ballot-res-address-street"],
            ["mailin-ballot-res-address-street-2"],
            ["mailin-ballot-res-address-unit-type", "mailin-ballot-res-address-unit-number"],
            [
              "mailin-ballot-res-address-city",
              "mailin-ballot-res-address-state",
              "mailin-ballot-res-address-zip",
            ],
          ],
          handler: "review",
          label: "Mail-in Ballot Address",
          key: "mailinballot",
          hidefn: (formData) =>
            !(formData["mail-in-ballot-options"] === "R") ||
            formData["no-penndot-number"] ||
            formData["request-mail-ballot"] !== "Yes",
        },
        {
          fieldGroups: [
            ["mailin-ballot-mailing-address-street"],
            [
              "mailin-ballot-mailing-address-city",
              "mailin-ballot-mailing-address-state",
              "mailin-ballot-mailing-address-zip",
            ],
          ],
          handler: "review",
          label: "Mail-in Ballot Address",
          key: "mailinballot",
          hidefn: (formData) =>
            !(formData["mail-in-ballot-options"] === "M") ||
            formData["no-penndot-number"] ||
            formData["request-mail-ballot"] !== "Yes",
        },
        {
          fieldGroups: [
            ["mail-in-ballot-address-street"],
            [
              "mail-in-ballot-address-city",
              "mail-in-ballot-address-state",
              "mail-in-ballot-address-zip",
            ],
          ],
          handler: "review",
          label: "Mail-in Ballot Address",
          key: "mailinballot",
          hidefn: (formData) =>
            !(formData["mail-in-ballot-options"] === "A") ||
            formData["no-penndot-number"] ||
            formData["request-mail-ballot"] !== "Yes",
        },
        {
          fieldGroups: [
            ["previous-address"],
            ["previous-city", "previous-state", "previous-zip"],
            ["previous-county"],
          ],
          handler: "review",
          label: "Previous Address",
          key: "previousAddress",
          dependency: ["change-address"],
        },
        {
          fieldGroups: [["voter-registration-number"]],
          handler: "review",
          label: "Voter Registration Number",
          key: "voterRegistrationNumber",
          dependency: ["voter-registration-number"],
        },
        {
          fieldGroups: [["year-previous-registration"]],
          handler: "review",
          label: "Year of Previous Registration",
          key: "previousRegistrationYear",
          dependency: ["year-previous-registration"],
        },
        {
          fieldGroups: [["political-party"], ["other-political-party"]],
          handler: "review",
          valueToLabel: Object.fromEntries(
            appJson.PoliticalParty.map((politicalParty) => [
              politicalParty.PoliticalPartyCode,
              politicalParty.PoliticalPartyDescription,
            ]),
          ),
          label: "Political Party",
          key: "politicalParty",
        },
        {
          fieldGroups: [["race"]],
          handler: "review",
          valueToLabel: Object.fromEntries(
            appJson.Race.map((race) => [race.RaceCode, race.RaceDescription]),
          ),
          label: "Race",
          hidefn: (formData) => {
            return formData["race"] == null;
          },
        },
        {
          fieldGroups: [["helper-name"], ["helper-address"], ["helper-phone"]],
          handler: "review",
          label: "Helper",
          dependency: ["someone-helped"],
          key: "helper",
        },
      ],
    },
    {
      title: "Thanks",
      slug: "confirmation",
      elements: [
        {
          handler: "text",
          value: <h2>About Your Voter Registration</h2>,
        },
        {
          uniqueKey: "submit-VR",
          handler: "text",
          // Value is added inside ./RegistrationFrom.js from const submitVRText;
        },
        {
          handler: "text",
          value: (
            <div>
              <hr />
              <h2>About Your Mail-In Ballot Request</h2>
            </div>
          ),
          hidefn: (formData) => formData["request-mail-ballot"] !== "Yes",
        },
        {
          uniqueKey: "submit-mail-in",
          handler: "text",
          // Value is added in ./RegistrationFrom.js from ./ballot-request.js
          hidefn: (formData) => formData["request-mail-ballot"] !== "Yes",
        },
        {
          handler: "text",
          value: (
            <div>
              <div className="muted paid-for">
                <p>
                  Paid for by Tech for Campaigns,{" "}
                  <a href="https://techforcampaigns.org">techforcampaigns.org</a>. Not authorized by
                  candidate or candidate&apos;s committee.
                </p>
              </div>
              <div className="muted">
                <p>
                  <a
                    href="https://www.techforcampaigns.org/privacy"
                    target="_blank"
                    rel="noopener noreferrer">
                    {l10n.getString("privacy")}
                  </a>
                </p>
              </div>
            </div>
          ),
        },
      ],
    },
  ];
}
