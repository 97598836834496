import React, { useState, useRef } from "react";
import Modal from "react-modal";
import SignatureCanvas from "react-signature-canvas";
import styled from "styled-components";

const modalStyles = {
  content: {
    top: "0",
    left: "0",
    right: "auto",
    bottom: "auto",
    width: "100%",
    height: "100vh",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
  },
};

const canvasStyles = {
  flex: 1,
  border: "1px solid rgba(0, 0, 0, 0.6)",
  height: "50vh",
  maxWidth: "100%",
};

const ModalInner = styled.div`
  width: 100%;
  max-width: 550px;
`;

const Button = styled.button`
  margin-right: 1rem;
`;

const SigImage = styled.img`
  width: 50%;
`;

const ModalBlock = styled.section`
  margin: 0.5rem 0;
  display: flex;
`;

const ModalText = styled.p`
  margin: 0;
`;

const wantWidth = 180;
const wantHeight = 60;

Modal.setAppElement("#root");

const SignatureBox = ({ updateValue, value }) => {
  const [isOpen, setIsOpen] = useState(false);

  const sigCanvas = useRef({});
  const scaledCanvasRef = useRef(null);

  const trimAndSave = () => {
    const trimmed = sigCanvas.current.getTrimmedCanvas();
    const trimmedWidth = trimmed.width;
    const trimmedHeight = trimmed.height;
    let scalar = 1;
    if (trimmedWidth >= 3 * trimmedHeight) {
      scalar = wantWidth / trimmedWidth;
    } else {
      scalar = wantHeight / trimmedHeight;
    }
    const scaledCanvas = scaledCanvasRef.current;
    scaledCanvas.width = wantWidth;
    scaledCanvas.height = wantHeight;
    const scaledCtx = scaledCanvas.getContext("2d");
    scaledCtx.fillStyle = "white";
    scaledCtx.fillRect(0, 0, wantWidth, wantHeight);
    const startX = (wantWidth - scalar * trimmedWidth) / 2;
    const startY = (wantHeight - scalar * trimmedHeight) / 2;
    scaledCtx.drawImage(
      trimmed,
      0,
      0,
      trimmedWidth,
      trimmedHeight,
      startX,
      startY,
      scalar * trimmedWidth,
      scalar * trimmedHeight,
    );
    const scaledImg = scaledCanvas.toDataURL("image/png");
    updateValue(scaledImg);
    setIsOpen(false);
  };

  const clearCanvas = () => {
    if (sigCanvas.current && sigCanvas.current.clear) {
      sigCanvas.current.clear();
    }
  };

  const clearAndOpen = () => {
    clearCanvas();
    setIsOpen(true);
  };

  const clearAndClose = () => {
    clearCanvas();
    setIsOpen(false);
  };

  return (
    <div>
      <div>
        <Button onClick={clearAndOpen}>
          {`${value > "" ? "Redo" : "Add"} Signature${!value ? " *" : ""}`}
        </Button>
        {value > "" && <Button onClick={() => updateValue("")}>Clear</Button>}
        {value > "" && (
          <ModalBlock>
            <SigImage src={value} />
          </ModalBlock>
        )}
        {!value && (
          <ModalBlock>
            <ModalText>
              <em>Signature is required.</em>
            </ModalText>
          </ModalBlock>
        )}
        <Modal isOpen={isOpen} contentLabel="Signature Modal" style={modalStyles}>
          <ModalBlock>
            <ModalInner>
              <ModalText>
                Use your mouse (on web) or finger (on mobile) to sign in the box below.
              </ModalText>
            </ModalInner>
          </ModalBlock>
          <ModalBlock>
            <ModalInner>
              <SignatureCanvas
                penColor="black"
                minWidth={2}
                maxWidth={4}
                ref={sigCanvas}
                canvasProps={{
                  style: canvasStyles,
                  className: "sigCanvas",
                }}
              />
            </ModalInner>
          </ModalBlock>
          <ModalBlock>
            <ModalInner>
              <Button onClick={trimAndSave}>Save</Button>
              <Button onClick={clearAndClose}>Cancel</Button>
              {sigCanvas.current && sigCanvas.current.clear && (
                <Button onClick={clearCanvas}>Clear</Button>
              )}
            </ModalInner>
          </ModalBlock>
        </Modal>
        <canvas
          ref={scaledCanvasRef}
          // Rounding is important so the canvas width and height matches/is a multiple for sharpness.
          style={{
            width: 180,
            height: 60,
            display: "none",
          }}
        />
      </div>
    </div>
  );
};

export default SignatureBox;
